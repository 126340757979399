import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Container = styled.div`
    background: ${props => props.theme.primary.surfaceDefaultSmoke};
    border-radius: 2px;
    padding: 1.5rem;
    display: grid;
    align-items: center;
    grid-template-columns: 3.5rem 1fr;
    column-gap: 1.5rem;
    row-gap: 0.75rem;

    @media (max-width: 584px) {
        grid-template-columns: 2rem 1fr;
        padding: 0.5rem;
    }
`;

export const UserInfoContainer = styled.div`
    overflow: hidden;
`;

export const Name = styled.div`
    color: ${props => props.theme.typography.body.color};
    font-size: 1rem;
    font-family: Roboto;
    font-weight: 500;
    line-height: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const Email = styled.div`
    color: ${props => props.theme.primary.textSubduedColor};
    font-size: 1rem;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.29px;
    line-height: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const FeedbackContainer = styled.div`
    grid-column-start: 2;
    color: ${props => props.theme.typography.body.color};
    font-size: 1rem;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.29px;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
`;

export const SuccessIcon = styled(FontAwesomeIcon)`
    color: ${props => props.theme.primary.successed};
    font-size: 1rem;
`;
