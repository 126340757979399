type FlowType = "completeInvitation" | "default";

export function isValidFlowType(s: string | null): s is FlowType {
    if (!s) {
        return false;
    }

    return ["completeInvitation", "default"].includes(s);
}

export default FlowType;
