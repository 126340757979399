import { getRestCall } from "../../utils/rest-api-call";
import { API_URL } from "../../config";

const loginActionPrefix = "TS_ID_V3_LOGIN";
export const LOGIN_RESET = `${loginActionPrefix}/reset`;

export async function getNonce(userId: string) {
    const res = await getRestCall<{ nonce: string }>(`${API_URL}/login/digital/nonce`, null, {
        userId
    });

    return res.nonce;
}
