import React from "react";
import { createRoot } from "react-dom/client";

import { useTheme } from "@mui/material/styles";
import { VaporThemeProvider } from "@vapor/react-material";
import { Provider } from "react-redux";
import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components";

import "./index.css";
import store from "./reducers";
import Routes from "./views";

const RoutesWrapper = () => {
    const theme = useTheme();

    return (
        <StyledComponentsThemeProvider theme={{ ...theme.palette, typography: theme.typography }}>
            <Routes />
        </StyledComponentsThemeProvider>
    );
};

const domNode = document.getElementById("root")!!;
const root = createRoot(domNode);
root.render(
    <Provider store={store}>
        <VaporThemeProvider>
            <RoutesWrapper />
        </VaporThemeProvider>
    </Provider>
);
