import styled from "styled-components";

export const Description = styled.div`
    color: ${props => props.theme.primary.textSubduedColor};
    font-size: 1rem;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.44px;
    line-height: 1.5rem;
`;

export const InputContainer = styled.div`
    margin-top: 1rem;
    position: relative;
`;

export const InputAction = styled.div`
    color: ${props => props.theme.primary.interactiveDefault};
    font-size: 0.75rem;
    font-family: Roboto;
    font-weight: 500;
    text-align: right;
    letter-spacing: 0px;
    line-height: 1rem;
    position: absolute;
    top: 0.5rem;
    right: 0;
    z-index: 1;
    cursor: pointer;

    & > a {
        color: ${props => props.theme.primary.interactiveDefault};
        font-size: 0.75rem;
        font-family: Roboto;
        font-weight: 500;
        letter-spacing: 0px;

        :hover {
            color: #00c3ea;
        }
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    margin-top: 1.5rem;
`;
