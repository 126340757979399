import styled from "styled-components";

export const Title = styled.div`
    color: ${props => props.theme.primary.textTitleColor};
    font-size: 1.5rem;
    font-family: Cairo;
    font-weight: 600;
    letter-spacing: 0.18px;
    line-height: 2rem;
    margin-top: 2rem;
`;

export const Subtitle = styled.div`
    color: ${props => props.theme.primary.textSubduedColor};
    font-size: 1rem;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.44px;
    line-height: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

export const ButtonsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    margin-top: 2.5rem;

    @media (max-width: 584px) {
        grid-template-columns: 1fr;
        grid-gap: 1rem;
    }
`;
