import React from "react";

import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";

import LoggedUser from "../../../../components/general/logged-user";
import { BodyCopy, H2 } from "../../../../components/general/typography";
import { Container, LoggedUserContainer, SpinnerContainer } from "./styled";

type LoggedContentProps = {
    appName: string;
    firstName: string;
    lastName: string;
    tsIdEmail: string;
};
const LoadingContent = ({ appName, firstName, lastName, tsIdEmail }: LoggedContentProps) => {
    return (
        <Container>
            <SpinnerContainer>
                <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
            </SpinnerContainer>
            <H2 color="darkImperialBlue">
                <FormattedMessage id="v-connect-account-feedback.loading.title" />
            </H2>
            <BodyCopy color="cadet">
                <FormattedMessage id="v-connect-account-feedback.connecting" />
            </BodyCopy>
            <LoggedUserContainer>
                <LoggedUser name={firstName} surname={lastName} email={tsIdEmail} showFeedback={false} />
            </LoggedUserContainer>
            <BodyCopy color="cadet">
                <FormattedMessage
                    id="v-connect-account-feedback.connecting.long-operation"
                    values={{ appName, link: chunk => <span>{chunk}</span> }}
                />
            </BodyCopy>
        </Container>
    );
};

export default LoadingContent;
