import { ActionCreatorWithoutPayload, AsyncThunk, createReducer } from "@reduxjs/toolkit";
import { TypedActionCreator } from "@reduxjs/toolkit/dist/mapBuilders";

const initialStatus: { started: boolean; ended: boolean; error: boolean; errorInfo: any } = {
    started: false,
    ended: false,
    error: false,
    errorInfo: {}
};
export function createStatusReducer<Returned, ThunkArg>(
    thunkAction: AsyncThunk<Returned, ThunkArg, any>,
    resetAction: TypedActionCreator<any> | ActionCreatorWithoutPayload
) {
    return createReducer(initialStatus, builder => {
        builder
            .addCase(thunkAction.pending, state => {
                state = { ...initialStatus };
                state.started = true;
                return state;
            })
            .addCase(thunkAction.fulfilled, state => {
                state.started = false;
                state.ended = true;
            })
            .addCase(thunkAction.rejected, (state, { error }) => {
                state.started = false;
                state.error = true;
                state.errorInfo = error;
            })
            .addCase(resetAction, state => initialStatus)
            .addDefaultCase(state => state);
    });
}
