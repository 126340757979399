import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { getRestCall } from "../../utils/rest-api-call";
import { API_URL } from "../../config";

export const checkTsIdConnectedPrefix = "CHECK_TS_ID_CONNECTED";
export const CHECK_TS_ID_CONNECTED_RESET = `${checkTsIdConnectedPrefix}/reset`;

export type CheckTsIdConnectedParameters = { tsId: string; tsUserCode: string };
export type CheckTsIdConnectedActionPayload = {
    exists: boolean;
    tsIdConnected: boolean;
    localUser: boolean;
    invitedUser: boolean;
};
export const checkTsIdConnected = createAsyncThunk(
    checkTsIdConnectedPrefix,
    async ({ tsId, tsUserCode }: CheckTsIdConnectedParameters) => {
        return getRestCall<CheckTsIdConnectedActionPayload>(
            `${API_URL}/login/tsid/v3/exists`,
            {},
            { tsId, tsUserCode }
        );
    }
);
export const checkTsIdConnectedReset = createAction(CHECK_TS_ID_CONNECTED_RESET);
