import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { putRestCall } from "../../utils/rest-api-call";
import { API_URL } from "../../config";

export const completeInvitationPrefix = "COMPLETE_INVITATION";
export const COMPLETE_INVITATION_RESET = `${completeInvitationPrefix}/reset`;

export type CompleteInvitationParameters = { tsId: string; token: string };
export const completeInvitation = createAsyncThunk(
    completeInvitationPrefix,
    async ({ tsId, token }: CompleteInvitationParameters, config) => {
        await putRestCall(
            `${API_URL}/users/${tsId}/invitation/complete`,
            { securityToken: token },
            {},
            {},
            config.dispatch
        );
    }
);
export const completeInvitationReset = createAction(COMPLETE_INVITATION_RESET);
