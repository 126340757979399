import { createAsyncThunk } from "@reduxjs/toolkit";
import sha256 from "sha256";
import { putRestCall } from "../../utils/rest-api-call";
import { API_URL } from "../../config";

const resetPasswordPrefix = "RESET_PWD";
export const RESET_PWD_RESET = `${resetPasswordPrefix}/reset`;

export type ResetPasswordParameters = {
    id: string;
    password: string;
    token: string;
};
export const resetPassword = createAsyncThunk(
    resetPasswordPrefix,
    ({ id, password, token }: ResetPasswordParameters, config) => {
        const sha256UserPassword = sha256(id + password);

        return putRestCall(
            `${API_URL}/login/digital/reset`,
            null,
            null,
            {
                sha256UserPassword,
                id,
                token
            },
            config.dispatch
        );
    }
);
