import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { CAPTCHA_SITE_KEY } from "../../../config";

type ReCaptchaProps = {
    captcha: string;
    onChange: (token: string | null) => void;
    error?: string;
};
function ReCaptcha({ captcha, onChange, error }: ReCaptchaProps) {
    return (
        <div className="ResetPassword-Captcha">
            <ReCAPTCHA ref={captcha} onChange={onChange} sitekey={CAPTCHA_SITE_KEY || "undefined"} />
            <p className="captcha-err" style={{ color: "red" }}>
                {error}
            </p>
            <br />
        </div>
    );
}

export default ReCaptcha;
