import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { postRestCall } from "../../utils/rest-api-call";
import { API_URL } from "../../config";

export const ssoCreateUserPrefix = "SSOCREATE_USER";
export const SSOCREATE_USER_RESET = `${ssoCreateUserPrefix}/reset`;

export type ssoCreateUserParameters = { token: string; userData: any };
export const ssoCreateUser = createAsyncThunk(
    ssoCreateUserPrefix,
    async ({ token, userData }: ssoCreateUserParameters) => {
        return postRestCall(`${API_URL}/users`, { securityToken: token }, null, userData);
    }
);
export const ssoCreateUserReset = createAction(SSOCREATE_USER_RESET);
