import jwtDecode from "jwt-decode";
import jumpToApp from "./jump-to-app";
import LoginMode from "../types/LoginMode";
import { DEFAULT_APPLICATION } from "../config";

export async function sendLoginCompleteEvent(
    token: string,
    refreshToken: string,
    idToken: string,
    targetUrl: string,
    mode: LoginMode,
    app?: string,
    deepLink?: string
) {
    const { sub } = jwtDecode<{ sub: string }>(token);

    if (mode !== "redirect") {
        window.opener.postMessage(
            {
                id: sub,
                type: "TOKEN",
                token,
                refreshToken,
                idToken,
                loginMethod: "TSID"
            },
            targetUrl
        );
    } else {
        jumpToApp(app || DEFAULT_APPLICATION, deepLink);
    }
}
