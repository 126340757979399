import pkceChallenge from "pkce-challenge";

import { TS_ID_AUTHORIZATION_ENDPOINT, TS_ID_CALLBACK_URL, TS_ID_CLIENT_ID } from "../config";
import Base64Utils from "./base64-utils";
import FlowType from "../types/FlowType";

function generateTsIdLoginUrl(
    appName: string,
    flowType: FlowType,
    userEmail?: string | null,
    referrer = document.referrer,
    mode = "popup",
    appId?: string | null,
    deepLink?: string | null,
    skipLinking?: boolean | null,
    acrValues?: string | null
): string {
    const { code_challenge, code_verifier } = pkceChallenge(128);
    localStorage.setItem("code_verifier", code_verifier);

    const stateParams = {
        referrer: decodeURIComponent(referrer),
        appName: decodeURIComponent(appName),
        flowType: decodeURIComponent(flowType),
        userEmail: userEmail && decodeURIComponent(userEmail),
        mode,
        appId,
        deepLink,
        skipLinking
    };

    const stateValue = Base64Utils.encode(stateParams);

    const tsIdParams = {
        client_id: TS_ID_CLIENT_ID,
        redirect_uri: TS_ID_CALLBACK_URL,
        response_type: "code",
        scope: "openid offline_access Engagement",
        state: stateValue,
        code_challenge: code_challenge,
        code_challenge_method: "S256",
        acr_values: acrValues
    };

    const queryString = Object.entries(tsIdParams)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");

    return `${TS_ID_AUTHORIZATION_ENDPOINT}?${queryString}`;
}

export default generateTsIdLoginUrl;
