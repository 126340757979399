import styled from "styled-components";

export const Container = styled.div`
    background-color: ${props => props.theme.primary.white};
    padding: 2.5rem 5rem;
    width: 36.5rem;
    min-height: 31.25rem;
    box-shadow: 0px 4px 16px 0px rgba(21, 41, 53, 0.32);
    overflow: scroll;
    display: flex;
    flex-direction: column;
    ::-webkit-scrollbar {
        display: none;
        scrollbar-width: none;
    }

    @media (max-width: 584px) {
        width: 100%;
        padding: 1.5rem 1rem 9rem 1rem;
        min-height: 100vh;
    }
`;

export const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;
`;

export const Logo = styled.img`
    max-width: 13rem;
`;
