import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import LoginModalContainer from "../../../components/login/login-modal-container";
import LoginModal from "../../../components/general/login-modal";
import { ErrorIcon } from "../../invite-feedback/styled";
import { Box, Button, Typography } from "@vapor/react-material";

type ForgotPasswordErrorProps = {
    forgotPasswordReset: () => void;
};
function ForgetPasswordError({ forgotPasswordReset }: ForgotPasswordErrorProps) {
    const intl = useIntl();

    return (
        <LoginModalContainer>
            <LoginModal
                title={
                    <>
                        <ErrorIcon>
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                        </ErrorIcon>
                        <Box component="div">
                            <FormattedMessage id="forgot-password.title-error" />
                        </Box>
                    </>
                }
            >
                <Typography sx={{ mb: 3 }} align="center" color="primary.textSubduedColor" variant="bodyLarge">
                    <FormattedMessage id="v-forgot-password-error" />
                </Typography>
                <Button onClick={forgotPasswordReset} variant="contained">
                    {intl.formatMessage({
                        id: "forgot-password.button-error"
                    })}
                </Button>
            </LoginModal>
        </LoginModalContainer>
    );
}

export default ForgetPasswordError;
