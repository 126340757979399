import styled from "styled-components";

import { TS_DIGITAL_IMAGES } from "../../../config";
import backgroundImage from "./background.svg";

const cities = [
    "bologna",
    "campobasso",
    "catania",
    "ferrara",
    "milano",
    "napoli",
    "pesaro",
    "pescara",
    "roma",
    "torino"
];

/* prettier-ignore */
export const Container = styled.div<{showAnimatedBackgroundImage: boolean}>`
    min-height: 100vh;
    position: relative;
    background-color: #fff;
    background-image: ${props => props.showAnimatedBackgroundImage ? `url(${TS_DIGITAL_IMAGES}login-backgrounds/${cities[Math.floor(Math.random() * cities.length)]}.jpg)` : `url(${backgroundImage})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

export const ChildrenContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;

    & > * {
        z-index: 1;
    }
`;

export const Children = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
