import React, { SyntheticEvent, useRef, useState } from "react";

import { useIntl } from "react-intl";
import { EmailValidator } from "commons-validator-js";
import "./style.css";

import { forgotPassword, forgotPasswordReset } from "../../../actions/auth";

import ForgotPasswordSuccess from "./ForgetPasswordSuccess";
import ForgetPasswordError from "./ForgetPasswordError";
import ForgotPasswordForm from "./ForgetPasswordForm";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";

export function ForgotPassword() {
    const passwordForgotStatus = useAppSelector(state => state.password.forgot.status);
    const dispatch = useAppDispatch();

    const intl = useIntl();
    const captcha = useRef<any>();

    const [email, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [emailError, setEmailError] = useState("");

    const [reCaptchaCode, setReCaptchaCode] = useState("");
    const [recaptchaError, setReCaptchaError] = useState("");

    function onCheckReCaptcha(value: string | null) {
        window.setTimeout(function () {
            setReCaptchaCode(value || "");
        }, 0);
    }

    function handleSubmit(event: SyntheticEvent) {
        event.preventDefault();
        event.stopPropagation();

        const isValidEmail = new EmailValidator().isValid(email);
        setIsEmailValid(isValidEmail);

        if (isValidEmail) {
            // Clear error
            setEmailError("");
        } else {
            setEmailError(
                intl.formatMessage({
                    id: "v-forgot-password-error-invalid-mail"
                })
            );
        }

        if (reCaptchaCode === "") {
            setReCaptchaError(
                intl.formatMessage({
                    id: "v-forgot-password-error-captcha"
                })
            );
        }

        if (isValidEmail && reCaptchaCode !== "") {
            dispatch(forgotPassword({ email, captchaCode: reCaptchaCode }));
        }
    }

    if (passwordForgotStatus.ended) {
        return <ForgotPasswordSuccess />;
    }

    if (passwordForgotStatus.error) {
        captcha.current.reset();
        return <ForgetPasswordError forgotPasswordReset={() => dispatch(forgotPasswordReset)} />;
    }

    return (
        <ForgotPasswordForm
            email={email}
            setEmail={setEmail}
            emailError={emailError}
            isEmailValid={isEmailValid}
            handleSubmit={handleSubmit}
            captcha={captcha.current}
            onCheckReCaptcha={onCheckReCaptcha}
            recaptchaError={recaptchaError}
        />
    );
}

export default ForgotPassword;
