import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Button, Typography } from "@vapor/react-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import LoginModal from "../../../../components/general/login-modal";
import LoginModalContainer from "../../../../components/login/login-modal-container";

import { ErrorIcon } from "../../../sso/connect-account-feedback/error/styled";

const ResetError = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    return (
        <LoginModalContainer>
            <LoginModal
                title={
                    <>
                        <ErrorIcon>
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                        </ErrorIcon>
                        <div style={{ textAlign: "center" }}>
                            <FormattedMessage id="general.error" />
                        </div>
                    </>
                }
            >
                <Typography sx={{ mb: 3 }} align="center" color="primary.textSubduedColor" variant="bodyLarge">
                    <FormattedMessage id="v-form-reset-error" />
                </Typography>
                <Button onClick={() => navigate(0)} style={{ marginTop: "150px" }} variant="contained">
                    {intl.formatMessage({
                        id: "forgot-password.button-error"
                    })}
                </Button>
            </LoginModal>
        </LoginModalContainer>
    );
};

export default ResetError;
