import { Button } from "@vapor/react-material";
import styled from "styled-components";

export const SuccessIcon = styled.div`
    color: ${props => props.theme.primary.successed};
    font-size: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

export const Title = styled.div`
    color: ${props => props.theme.primary.textTitleColor};
    font-size: 2rem;
    font-family: Cairo;
    font-weight: 600;
    line-height: 2.5rem;
`;

export const Description = styled.div`
    color: ${props => props.theme.primary.textSubduedColor};
    font-size: 1rem;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.44px;
    line-height: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

export const LoggedUserContainer = styled.div`
    margin-bottom: 2.5rem;
`;

export const LinkButton = styled.button`
    border: 0;
    margin: 0;
    padding: 0;
    background: transparent;
    cursor: pointer;

    color: ${props => props.theme.primary.interactiveDefault};
    text-decoration: none;
    font-size: 1rem;

    :hover {
        color: ${props => props.theme.primary.textTitleColor};
    }
`;

export const FullWidthButton = styled(Button)`
    margin-top: 1rem;
    width: 100%;
`;
