import styled from "styled-components";

export const ErrorIcon = styled.div`
    color: ${props => props.theme.primary.baseCriticalColor};
    font-size: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

export const Title = styled.div`
    color: ${props => props.theme.primary.textTitleColor};
    font-size: 2rem;
    font-family: Cairo;
    font-weight: 600;
    line-height: 2.5rem;
`;

export const Description = styled.div`
    color: ${props => props.theme.primary.textSubduedColor};
    font-size: 1rem;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.44px;
    line-height: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    margin-top: 7.5rem;
`;
