import React, { ReactNode } from "react";

import {
    faCheckCircle,
    faExclamationCircle,
    faInfoCircle,
    faMinusCircle,
    faTimes
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { BodyCopy } from "../typography";
import { CloseButtonContainer, Container, IconContainer, MessageContainer } from "./styled";

type NotificationProps = {
    closable: boolean;
    message: string | ReactNode;
    onClick?: () => {};
    variant: "informative" | "check" | "warning" | "error";
};
const Notification = ({ closable, message, onClick, variant }: NotificationProps) => {
    const icon = {
        informative: faInfoCircle,
        check: faCheckCircle,
        warning: faExclamationCircle,
        error: faMinusCircle
    };

    return (
        <Container closable={closable} variant={variant}>
            <IconContainer variant={variant}>
                <FontAwesomeIcon icon={icon[variant]} />
            </IconContainer>
            <MessageContainer>
                <BodyCopy weight="500" color="yankeesBlue">
                    {message}
                </BodyCopy>
            </MessageContainer>
            {closable && (
                <CloseButtonContainer onClick={onClick}>
                    <FontAwesomeIcon icon={faTimes} />
                </CloseButtonContainer>
            )}
        </Container>
    );
};

Notification.defaultProps = {
    closable: false,
    variant: "informative"
};

export default Notification;
