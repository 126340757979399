import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useIntl } from "react-intl";

import ResetSuccess from "./reset-success";
import ResetError from "./reset-error";
import ResetForm from "./reset-form";

import { resetPassword } from "../../../actions/auth";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";

// Password testing rules
const rules = [
    {
        regex: "[a-z]",
        messageId: "lowercase"
    },
    {
        regex: "[A-Z]",
        messageId: "uppercase"
    },
    {
        regex: "[0-9]",
        messageId: "number"
    },
    {
        regex: "[!\"#$%&'()*+,-./:;<=>?@[\\\\\\]^_`{|}]",
        messageId: "special-char"
    }
];

export const ResetPassword = () => {
    const passwordResetStatus = useAppSelector(state => state.password.reset.status);

    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const intl = useIntl();

    const [error, setError] = useState<string | null>(passwordResetStatus.errorInfo.message);

    const handleSubmit = (password: string, repeatedPassword: string) => {
        // If there is a request in progress dont continue to execute
        if (passwordResetStatus.started) return;

        // Check if two passwords provided are the same
        if (password !== repeatedPassword) {
            setError(
                intl.formatMessage({
                    id: "c-form-reset.password-not-match"
                })
            );
            return;
        }

        // Check the length
        if (password.length < 8) {
            setError(
                intl.formatMessage({
                    id: "e-password-length"
                })
            );
            return;
        }

        // Check each rule
        for (let rule of rules) {
            if (!new RegExp(rule.regex).test(password)) {
                setError(
                    intl.formatMessage({
                        id: `e-password-${rule.messageId}`
                    })
                );
                return;
            }
        }

        // Clear any error and reset the password
        setError(null);
        dispatch(
            resetPassword({
                id: searchParams.get("id") || "undefined",
                password,
                token: searchParams.get("token") || "undefined"
            })
        );
    };

    // When the user land on the page or when the form submition is pending
    if (!passwordResetStatus.ended && !passwordResetStatus.error) {
        return <ResetForm onSubmit={handleSubmit} onProgress={passwordResetStatus.started} error={error} />;
    }
    if (passwordResetStatus.ended) {
        return <ResetSuccess />;
    }
    return <ResetError />;
};

export default ResetPassword;
