const es = {
    "c-form-reset.label.password-new": "Nueva contraseña",
    "c-form-reset.placeholder.password-new": "Introduce la nueva contraseña",
    "c-form-reset.label.password-new-repeated": "Confirma la nueva contraseña",
    "c-form-reset.placeholder.password-new-repeated": "Introduce otra vez la nueva contraseña",
    "c-form-reset.password-not-match": "¡Las contraseñas no son iguales!",
    "c-form-login.button.text": "Accede sin TeamSystem ID",
    "c-form-login.password-forgotten": "¿Has olvidado la contraseña?",
    "c-form-login.retrieve-credentials": "Recupera mis credenciales",
    "c-form-login.sso.insert-credential":
        "Introduce las credenciales que utilizas para acceder a TS Digital relacionadas con el usuario conectado a TeamSystem ID ",
    "c-form-login.ssoaccess.text": "Accede",
    "c-form-login.ssoconnect.text": "Conecta",
    "c-form-sso.login": "Accede con TeamSystem ID",
    "c-login.divider": "o",
    "c-login.signup-question": "¿No tienes cuenta?",
    "c-login.ts-id": "Accede utilizando la cuenta única de TeamSystem",
    "c-registry-user-tech.login.error.generic":
        "Se produjo un error en el proceso de verificación de credenciales. Por favor, inténtalo de nuevo más tarde.",
    "c-registry-user-tech.login.error.notValid":
        "Las credenciales ingresadas no parecen ser válidas. Verifica el ID y la contraseña y vuelve a intentarlo.",
    "c-retrieve-credentials.company-data-step.recover-credentials-button": "Recuperar credenciales",
    "c-retrieve-credentials.company-data-step.taxId-placeholder": "Introduce el NIF",
    "c-retrieve-credentials.company-data-step.title": "Introduce el NIF asociado a la cuenta",
    "c-retrieve-credentials.company-data-step.vatNumber-placeholder": "Introduce NIF Intracomunitario",
    "c-retrieve-credentials.tech-keys-step.title":
        "Para recuperar el correo electrónico de tu cuenta, ingresa el ID de la clave técnica que hayas creado y la contraseña asociada a ella.",
    "c-retrieve-credentials.tech-keys-step.techId-label": "ID clave técnica",
    "c-retrieve-credentials.tech-keys-step.techId-placeholder": "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
    "c-retrieve-credentials.tech-keys-step.techSecret-label": "Contraseña",
    "c-retrieve-credentials.tech-keys-step.techSecret-placeholder": "Introduce la contraseña",
    "c-retrieve-credentials.user-retrieved-step.title":
        "El proceso ha acabado. Hemos asociado el correo electrónico a los datos introducido:",
    "c-retrieve-credentials.user-retrieved-step.back-to-login-button": "Vuelve a la página de inicio",
    "c-user-connect.tsid.label": "TeamSystem ID",
    "c-logged-user.success-feedback": "TeamSystem ID conectado",
    "forgot-password.title": "¿Has olvidado la contraseña?",
    "forgot-password.title-success": "Contraseña restablecida",
    "forgot-password.title-error": "Correo no válido",
    "forgot-password.button-continue": "Vuelve al inicio",
    "forgot-password.button-error": "Reintentar la solicitud",
    "reset-password.title": "Crea una nueva contraseña",
    "reset-password.tittle-success": "¡Tu contraseña ha sido modificada con éxito!",
    "reset-password.subtittle-success":
        "Proceso completado, tu contraseña ha sido modificada con éxito. Vuelve al inicio de sesión para acceder a la plataforma.",
    "reset-password.button.continue": "Vuelve al inicio",
    "gdpr.login-form.invalid-email": "Introduce un correo válido",
    "general.annulla": "Cancelar",
    "general.codice-fiscale": "NIF",
    "general.continue": "Siguiente",
    "general.country": "País",
    "general.email": "Email",
    "general.form.field-required": "Campo obligatorio",
    "general.goBack": "Volver",
    "general.password": "Contraseña",
    "general.PIVA": "NIF Intracomunitario",
    "general.privacy.disclaimer": "Declaración de privacidad",
    "general.retry": "Reintentar",
    "general.see-more": "Más información",
    "general.selectOne": "Selecciona",
    "general.important-notice": "¡Aviso importante!",
    "general.confirm": "Confirmar",
    "general.error": "Error",
    "general.attention": "¡Atención!",
    "general.login-with-ts-id": "Accede con TeamSystem ID",
    "general.remind-me-later": "Recuérdamelo en el próximo acceso",
    "login.login-or-signup-with-ts-id": "Accede o regístrate con TeamSystem ID",
    "login.login-or-signup-without-ts-id": "Accede o regístrate sin TeamSystem ID",
    "login.login-to-teamsystem-digital": "Accede a TeamSystem Digital",
    "login.login-tsid-info": " Introduce tu TeamSystem ID (TSID) y tu contraseña para acceder a TeamSystem Digital.",
    "login.login-with-credential":
        "Si no dispones de TeamSystem ID Introduce tus credenciales para efectuar el acceso a TeamSystem Digital.",
    "login.retrieve-your-email": "Recupera tu Email",
    "login.signup-to-teamsystem-id": "¿Todavía no tienes TeamSystem ID? Regístrate ahora",
    "referrer-gate.whitelist-retrieval-error":
        "Se ha encontrado un error en el acceso a la página, inténtalo más tarde.",
    "referrer-gate.unauthorized-referrer": "Imposible completar el acceso.",
    "v-forgot-password-message":
        "Introduce el email del usuario del cual has olvidado la contraseña y te enviaremos un correo para resetearla.",
    "v-forgot-password-error-captcha": "Captcha erróneo, Reintentar",
    "v-forgot-password-error-invalid-mail": "E-mail no valido, Reintentar",
    "v-forgot-password-error-not-on-system-mail": "E-mail no registrado",
    "v-forgot-password-success": "Tu contraseña ha sido restablecida con éxito, revisa tu bandeja de entrada.",
    "v-forgot-password-error":
        "El correo electrónico no está registrado en nuestro sistema, intenta el proceso de recuperación de contraseña nuevamente.",
    "v-form-reset-error": "Error durante el restablecimiento de la contraseña",
    "v-reset-password-message": "Introduce la nueva contraseña que deseas utilizar para acceder.",
    "v-retrieve-credentials.company-does-not-exist": "La empresa especificada no existe",
    "v-retrieve-credentials.retrieval-error":
        "Se ha producido un error en el proceso de recuperación de credenciales. Por favor, inténtalo de nuevo más tarde",
    "v-retrieve-credentials.unauthorized": "La empresa seleccionada no está vinculada a esta cuenta de aplicación",
    "v-first-access.modal-title": "Has realizado el primer acceso con TeamSystem ID.",
    "v-first-access.title": "¿Tienes una cuenta de TeamSystem Digital que deseas vincular?",
    "v-first-access.subtitle": "Vincúlala ahora para ver los datos de tu cuenta y seguir accediendo con TeamSystem ID.",
    "v-first-access.connect-account-option.confirm": "Sí, quiero vincular mi cuenta de TS Digital a TeamSystem ID",
    "v-first-access.connect-account-option.decline": "No, quiero configurar una nueva cuenta",
    "v-connect-tsid-to-digital-account.modal-title": "Vincula el TeamSystem ID a tu cuenta TS Digital",
    "v-connect-tsid-to-digital-account.connect": "Accede y vincula la cuenta",
    "v-connect-tsid-to-digital-account.description":
        "Inicia sesión utilizando tus credenciales personales para crear la conexión y acceder a tu cuenta con el único usuario TeamSystem ID.",
    "v-connect-account-feedback.connecting": "Estamos vinculando tu TeamSystem ID a tu cuenta de TeamSystem Digital.",
    "v-connect-account-feedback.connecting.long-operation":
        "Esta operación podría llevar algunos minutos. Si no eres redirigido automáticamente a {appName} <link>contacta con atención al cliente.</link>",
    "v-connect-tsid-to-digital-account.local-user.description":
        "Existe una cuenta de TS Digital asociada al mismo correo electrónico del TeamSystem ID. Ingresa la contraseña que sueles utilizar para acceder y crear la conexión.",
    "v-connect-account-feedback.disclamer":
        "Te estamos redirigiendo a {appName}. Esta operación podría llevar algunos momentos. Si no eres redirigido automáticamente <link>haz clic aquí</link>.",
    "v-connect-account-feedback.mail-change.disclaimer":
        "<b>Información:</b> recibirás todas las comunicaciones de TS Digital y las aplicaciones en uso en la bandeja de entrada del correo electrónico del TeamSystem ID que has vinculado.",
    "v-connect-account-feedback.success.title": "Vinculación realizada con éxito",
    "v-connect-account-feedback.success.description":
        "A partir de hoy, podrás acceder a tu cuenta de TS Digital con el TeamSystem ID.",
    "v-connect-account-feedback.loading.title": "Vinculación en curso…",
    "v-connect-account-feedback.loading.description": "Estamos vinculando tu TeamSystem ID a tu cuenta de TS Digita.",
    "v-connect-account-feedback.error.title": "Algo salió mal. La vinculación no se completó con éxito",
    "v-connect-account-feedback.error.description":
        "Para volver a iniciar el proceso de vinculación, haz clic en 'Reintentar'. Si no puedes resolver el problema, contacta al soporte.",
    "v-connect-account-feedback.error.back-to-login": "Volver a la página de inicio de sesión",
    "v-connect-account-feedback.error.user-already-connected.title": "La vinculación no se completó con éxito",
    "v-connect-account-feedback.error.user-already-connected.description":
        "Las credenciales que ingresaste ya están vinculadas a otro TeamSystem ID. Si no recuerdas a que TeamSystem ID lo has vinculado, vuelve a la página de inicio de sesión, inicia sesión con las credenciales y visita la sección 'TeamSystem ID' en tu perfil.",
    "v-connect-account-feedback.error.wrong-credentials.title": "La vinculación no se completó con éxito",
    "v-connect-account-feedback.error.wrong-credentials.description":
        "El correo electrónico o la contraseña ingresados para crear la vinculación entre el TeamSystem ID y tu cuenta de TS Digital son incorrectos. Inténtalo de nuevo.",
    "v-connect-account-feedback.error.user-already-connected-to-tsid.description":
        "El TeamSystem ID con el que has iniciado sesión ya está vinculado a otra cuenta de TeamSystem Digital. Puedes:",
    "v-connect-account-feedback.error.user-already-connected-to-tsid.description.first":
        "Vincular otro TeamSystem ID a esta cuenta.",
    "v-connect-account-feedback.error.user-already-connected-to-tsid.description.second":
        "Iniciar sesión en la otra cuenta de TeamSystem Digital y contactar con atención al cliente para que desconecten este TeamSystem ID. Una vez desconectado, intenta nuevamente la vinculación.",
    "v-connect-account-feedback.error.user-already-connected-to-tsid.connect-another-user":
        "Vincular otro TeamSystem ID",
    "v-connect-digital-account-to-tsid.modal-title": "Vincula un TeamSystem ID a tu cuenta de TS Digital",
    "v-connect-digital-account-to-tsid.description.first-sentence":
        "Inicia sesión en TeamSystem para realizar la vinculación.",
    "v-connect-digital-account-to-tsid.description.second-sentence":
        "Serás redirigido a la página de inicio de sesión con el TeamSystem ID, donde podrás iniciar sesión. Una vez vinculado tu TeamSystem ID a tu cuenta de TS Digital, podrás acceder a todos los servicios de TeamSystem con una única cuenta.",
    "v-connect-digital-account-to-tsid.login-and-connect-button": "Accede y vincula TeamSystem ID",
    "v-have-already-tsid-account.description.attention":
        "A partir del <b>30/11/2022</b> solo será posible efectuar el acceso a la aplicación {appName} <b>con el TeamSystem ID</b>.",
    "v-have-already-tsid-account.description.have-already-tsid": "¿Ya tienes TeamSystem ID?",
    "v-have-already-tsid-account.description.login-with-tsid":
        "<b>Accede</b> con el TeamSystem ID <b>para vincularlo</b> a tu cuenta.",
    "v-have-already-tsid-account.title.dont-have-tsid": "¿No tienes TeamSystem ID?",
    "v-have-already-tsid-account.title.dont-have-tsid.first": "<b>Registra</b> TeamSystem ID.",
    "v-have-already-tsid-account.title.dont-have-tsid.second":
        "<b>Efectua el primer acceso</b> a tu nuevo TeamSystem ID para vincularlo a tu cuenta de TeamSystem Digital.",
    "v-have-already-tsid-account.title.dont-have-tsid.login-or-signup": "Accede o regístrate a TeamSystem ID",
    "v-have-already-tsid-account.title.dont-have-tsid.remember-me-later": "Recuérdamelo en el próximo acceso",
    "v-important-notice.description.from":
        "A partir del <b>30/11/2022</b> solo será posible efectuar el acceso a la aplicación {appName} con el TeamSystem ID.",
    "v-important-notice.description.personal-credential-not-valid":
        "Ya tienes un TeamSystem ID vinculado a tu cuenta con este correo electrónico <b>{tsId}</b> así que no tienes de qué preocuparte. Sin embargo, ten en cuenta que <b>las credenciales que acabas de utilizar para acceder ya no serán válidas.</b>",
    "v-important-notice.button.ok": "Ok, lo he entendido",
    "v-invite-feedback.access-denied": "Acceso denegado",
    "v-invite-feedback.different-emails":
        "El TeamSystem ID con el que estás intentando acceder (<b>{loggedEmail}</b>) no es válido. Asegúrate de usar un TeamSystem ID con el mismo correo electrónico que se encuentra en la invitación, es decir, <b>{inviteEmail}</b>. Si no tienes un TeamSystem ID con el correo electrónico indicado, deberás registrarte para acceder a TS Digital.",
    "complete-invitation.failed": "Vaya, hubo un error",
    "complete-invitation.succeeded": "Genial, has completado el proceso de invitación",
    "complete-invitation.youAreAboutToBeRedirected": "¡Estás a punto de ser redirigido al portal!",
    "complete-invitation.ifRedirectDoesNotWorkClickHere":
        "Si sigues aquí en unos segundos, ¡haz clic aquí para ir al portal!",
    "v-login.unauthorized-message":
        "<p>Atención: el correo electrónico o la contraseña que has ingresado son incorrectos.</p><p>Verifica que no hayas ingresado por error el correo electrónico y la contraseña asociados a tu TeamSystem ID. Para acceder con el TeamSystem ID, haz clic en el botón correspondiente en la parte superior de la página de inicio de sesión.</p>",
    "v-login.generic-error": "Ups, hubo un error.",
    "e-password-length": "La contraseña debe contener al menos 8 caracteres",
    "e-password-uppercase": "La contraseña debe contener al menos una letra mayúscula",
    "e-password-lowercase": "La contraseña debe contener al menos una letra minúscula",
    "e-password-number": "La contraseña debe contener al menos un número",
    "e-password-special-char": "La contraseña debe contener al menos un carácter especial",
    "v-already-connected-to-tsid.from-date":
        "A partir del <b>1 dicembre 2022</b> solo puedes acceder a TeamSystem Digital con el TeamSystem ID.",
    "v-already-connected-to-tsid.ts-id-email":
        "<b>Ya hay un TeamSystem ID vinculado a tu cuenta</b> por lo que no debes preocuparte de nada. El correo asociado a tu TeamSystem ID es: <b>{email}</b>.",
    "v-already-connected-to-tsid.forgot-password":
        "Si no recuerdas la contraseña, recupérala desde la página de inicio de TeamSystem ID o accede simplemente.",
    "v-already-connected-to-tsid.info-box":
        "El proceso de implementación del TeamSystem ID como método de autenticación ha comenzado. Es posible que notes que algunos de tus compañeros aún acceden sin el TeamSystem ID; no te preocupes, les pediremos a todos los usuarios que hagan la transición en los próximos días.",
    "v-not-connected-to-tsid.from-date":
        "A partir del <b>1 diciembre 2022</b> solo puedes acceder a TeamSystem Digital con el TeamSystem ID.",
    "v-not-connected-to-tsid.already-have-a-tsid": "¿Ya tienes un TeamSystem ID?",
    "v-not-connected-to-tsid.login-and-connect":
        "<b>Accede</b> con el TeamSystem ID <b>para vincularlo</b> a tu cuenta.",
    "v-not-connected-to-tsid.dont-have-a-tsid": "¿No tienes un TeamSystem ID?",
    "v-not-connected-to-tsid.title.dont-have-a-tsid.first": "<b>Regístrate</b> en TeamSystem ID.",
    "v-not-connected-to-tsid.title.dont-have-a-tsid.second":
        "<b>Efectúa el primer acceso</b> a tu nuevo TeamSystem ID para vincularlo a tu cuenta de TeamSystem Digital.",
    "v-not-connected-to-tsid.info-box.forceTsIdLogin":
        "El proceso de implementación de TeamSystem ID como método de autenticación ha comenzado. Es posible que notes que algunos de tus colegas aún acceden sin el TeamSystem ID; no te preocupes, les pediremos a todos los usuarios que hagan la transición en los próximos días.",
    "v-not-connected-to-tsid.info-box.notForceTsIdLogin":
        "El proceso de implementación de TeamSystem ID ha comenzado. Recuerda que pronto las credenciales que sueles usar para acceder ya no serán válidas, así que te recomendamos vincular TeamSystem ID a tu cuenta lo antes posible; ¡solo llevará unos minutos!"
};

export default es;
