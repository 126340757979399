import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { Spinner } from "../../components/general/spinner";
import { API_URL, DEFAULT_APPLICATION, SKIP_LINKING_FLAG } from "../../config";
import FlowType, { isValidFlowType } from "../../types/FlowType";
import generateTsIdLoginUrl from "../../utils/generate-tsid-login-url";
import jumpToApp from "../../utils/jump-to-app";
import { CheckTsIdConnectedActionPayload, checkTsIdConnected } from "../../actions/tsid-v3";
import jwtDecode from "jwt-decode";
import { useAppDispatch } from "../../hooks/redux";

const LandingView = () => {
    const dispatch = useAppDispatch();
    const [loginNeeded, setLoginNeeded] = useState<boolean>(false);
    const location = useLocation();
    const [searchParams] = useSearchParams(location.search);

    const flowType: string | null = searchParams.get("flowType");
    const validatedFlowType: FlowType = isValidFlowType(flowType) ? flowType : "default";

    const params = {
        appName: searchParams.get("appName") || "di origine",
        userEmail: searchParams.get("userEmail"),
        referrer: searchParams.get("referrer") || document.referrer,
        skipLinking: SKIP_LINKING_FLAG || searchParams.get("skipLinking") === "true" ? true : false
    };
    const { appName, userEmail, referrer, skipLinking } = params;
    const mode = searchParams.get("mode") || "tab";
    const appId = searchParams.get("appId") || DEFAULT_APPLICATION;
    const acrValues = searchParams.get("acr_values"); 

    const deepLink = searchParams.get("deepLink");
    const encodedDeepLink = deepLink && encodeURIComponent(deepLink);

    useEffect(() => {
        if (mode === "redirect") {
            const fn = async () => {
                const res = await fetch(`${API_URL}/v1/token/refresh`, { method: "POST", credentials: "include" });

                if (res.ok) {
                    const { applicationToken } = await res.json();
                    const { email, sub } = jwtDecode<{ email: string; sub: string }>(applicationToken);
                    const connectedRes = await dispatch(checkTsIdConnected({ tsId: email, tsUserCode: sub }));
                    const payload = connectedRes.payload as CheckTsIdConnectedActionPayload;
                    if (payload.exists && payload.tsIdConnected) {
                        jumpToApp(appId, encodedDeepLink);
                    }
                }

                setLoginNeeded(true);
            };

            fn();
        } else {
            setLoginNeeded(true);
        }
    }, [appId, dispatch, encodedDeepLink, mode, params.referrer]);

    if (loginNeeded) {
        window.location.href = generateTsIdLoginUrl(
            appName,
            validatedFlowType,
            userEmail,
            referrer,
            mode,
            appId,
            encodedDeepLink,
            skipLinking,
            acrValues
        );
    }

    return <Spinner loading={true} />;
};

export default LandingView;
