import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { postRestCall } from "../../utils/rest-api-call";
import { API_URL } from "../../config";

export const tsIdV3LoginPrefix = "TS_ID_V3_LOGIN";
export const TS_ID_V3_LOGIN_RESET = `${tsIdV3LoginPrefix}/reset`;

export type TsIdV3LoginParameters = { code: string; codeVerifier: string };
export type TsIdV3LoginActionPayload = {
    accessToken?: string;
    tokenType?: string;
    expiresIn?: string;
    scope?: string;
    idToken?: string;
    refreshToken?: string;
};
export const tsIdV3Login = createAsyncThunk(
    tsIdV3LoginPrefix,
    async ({ code, codeVerifier }: TsIdV3LoginParameters) => {
        return postRestCall<TsIdV3LoginActionPayload>(
            `${API_URL}/login/tsid/v3/login`,
            {},
            {},
            {
                code,
                codeVerifier
            }
        );
    }
);
export const tsIdV3LoginReset = createAction(TS_ID_V3_LOGIN_RESET);
