import { Action, Middleware, ThunkAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import { AnyAction, Dispatch } from "redux";
import { createLogger } from "redux-logger";

import { ENABLE_CONSOLE_LOGS } from "../config";
import reducersSchema from "./schema";

const logger = createLogger({ collapsed: true });

const middlewares: Middleware[] = [];
const isRunningTest = "test" === process.env.NODE_ENV;
if (ENABLE_CONSOLE_LOGS && !isRunningTest) {
    console.log("Enable console logs", ENABLE_CONSOLE_LOGS);

    middlewares.push(logger as Middleware<{}, any, Dispatch<AnyAction>>);
}

const rootReducer = combineReducers(reducersSchema);
const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middlewares)
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;
