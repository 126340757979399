import { PrepareAction, createAction } from "@reduxjs/toolkit";

export const SET_CALLBACK_STATE = "SET_CALLBACK_STATE";
export const SET_CALLBACK_STATE_RESET = "SET_CALLBACK_STATE_RESET";
const setCallbackStateActionCreator: PrepareAction<{ originalReferrer: string; [key: string]: any }> = state => ({
    payload: {
        ...state,
        originalReferrer: state.referrer
    }
});
export const setCallbackState = createAction(SET_CALLBACK_STATE, setCallbackStateActionCreator);
export const setCallbackStateReset = createAction(SET_CALLBACK_STATE_RESET);
