import React, { ReactNode } from "react";

import { Children, ChildrenContainer, Container } from "./styled";

type LoginModalContainerProps = {
    children: ReactNode;
    showAnimatedBackgroundImage?: boolean;
};

const LoginModalContainer = ({ children, showAnimatedBackgroundImage = false }: LoginModalContainerProps) => {
    return (
        <Container showAnimatedBackgroundImage={showAnimatedBackgroundImage}>
            <ChildrenContainer>
                <Children>{children}</Children>
            </ChildrenContainer>
        </Container>
    );
};

export default LoginModalContainer;
