import { combineReducers } from "redux";

import loginOptions from "./login-options";
import { forgot, reset } from "./reset-password";
import tsIdV3 from "./tsid-v3";

const password = combineReducers({
    forgot,
    reset
});

const reducersSchema = {
    loginOptions,
    password,
    tsIdV3
};

export default reducersSchema;
