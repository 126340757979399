import styled from "styled-components";

export const Container = styled.div`
    margin-bottom: 1.5rem;
`;

export const LoggedUserContainer = styled.div`
    margin-top: 1rem;
    margin-bottom: 2.75rem;
`;

export const SpinnerContainer = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
    color: ${props => props.theme.primary.interactiveDefault};
    font-size: 5rem;
    margin-top: 3rem;
    margin-bottom: 2rem;
`;
