import styled from "styled-components";

export type SpinnerSize = "small" | "default" | "large";

const handleSize = (spinnerSize: SpinnerSize) => {
    switch (spinnerSize) {
        case "small":
            return "1rem";
        case "default":
            return "1.5rem";
        case "large":
        default:
            return "2.5rem";
    }
};

export const SpinnerContainer = styled.div<{ size: SpinnerSize }>`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
    color: ${props => props.theme.primary.interactiveDefault};
    font-size: ${props => handleSize(props.size)};
`;
