import jwtDecode from "jwt-decode";
import { combineReducers } from "redux";

import {
    CheckTsIdConnectedActionPayload,
    TsIdV3LoginActionPayload,
    checkTsIdConnected,
    checkTsIdConnectedReset,
    completeInvitation,
    completeInvitationReset,
    connectTsId,
    connectTsIdReset,
    setCallbackState,
    setCallbackStateReset,
    ssoCreateUser,
    ssoCreateUserReset,
    tsIdV3Login,
    tsIdV3LoginReset
} from "../actions/tsid-v3";
import { createReducer } from "@reduxjs/toolkit";
import TsIdTokenPayload from "../types/TsIdTokenPayload";
import { createStatusReducer } from "../utils/reducer-utils";

const loginStatus = createStatusReducer(tsIdV3Login, tsIdV3LoginReset);

const defaultLoginResultState: TsIdV3LoginActionPayload = {};
const loginResult = createReducer(defaultLoginResultState, builder => {
    builder
        .addCase(tsIdV3Login.pending, () => defaultLoginResultState)
        .addCase(tsIdV3Login.fulfilled, (_, { payload }) => payload)
        .addDefaultCase(state => state);
});

const tokenDataDefaultState: TsIdTokenPayload = {};
const tokenData = createReducer(tokenDataDefaultState, builder => {
    builder
        .addCase(tsIdV3Login.pending, () => tokenDataDefaultState)
        .addCase(tsIdV3Login.fulfilled, (_, { payload }) => {
            if (payload.accessToken) {
                return jwtDecode<TsIdTokenPayload>(payload.accessToken);
            }
            console.error("Missing accessToken in payload");
        })
        .addDefaultCase(state => state);
});

const login = combineReducers({ status: loginStatus, loginResult, tokenData });

const checkTsIdConnectedStatus = createStatusReducer(checkTsIdConnected, checkTsIdConnectedReset);

const initialConnectState: CheckTsIdConnectedActionPayload = {
    exists: false,
    invitedUser: false,
    localUser: false,
    tsIdConnected: false
};
const result = createReducer(initialConnectState, builder => {
    builder
        .addCase(checkTsIdConnected.fulfilled, (_, { payload }) => payload)
        .addCase(checkTsIdConnectedReset, () => initialConnectState)
        .addDefaultCase(state => state);
});

const checkTsIdConnectedReducer = combineReducers({ status: checkTsIdConnectedStatus, result });

const connectTsIdStatus = createStatusReducer(connectTsId, connectTsIdReset);

const connectTsIdPayloadInitialState: { userId?: string } = {};
const connectTsIdPayload = createReducer(connectTsIdPayloadInitialState, builder => {
    builder
        .addCase(connectTsId.pending, (state, { meta }) => {
            state.userId = meta.arg.userId;
        })
        .addCase(connectTsIdReset, () => connectTsIdPayloadInitialState)
        .addDefaultCase(state => state);
});
const connectTsIdReducer = combineReducers({ status: connectTsIdStatus, payload: connectTsIdPayload });

const completeInvitationReducer = combineReducers({
    status: createStatusReducer(completeInvitation, completeInvitationReset)
});

const initialCallbackState: { originalReferrer?: string; appName?: string; [key: string]: any } = {};
const callbackState = createReducer(initialCallbackState, builder => {
    builder
        .addCase(setCallbackState, (_, { payload }) => payload)
        .addCase(setCallbackStateReset, () => initialCallbackState)
        .addDefaultCase(state => state);
});

const ssoCreateUserReducer = combineReducers({
    state: createStatusReducer(ssoCreateUser, ssoCreateUserReset)
});

export default combineReducers({
    completeInvitation: completeInvitationReducer,
    ssoCreateUser: ssoCreateUserReducer,
    callbackState,
    checkTsIdConnected: checkTsIdConnectedReducer,
    connectTsId: connectTsIdReducer,
    login
});
