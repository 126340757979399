type ApiError = {
    message: string;
    code: string;
};

export function isApiError(o: unknown): o is ApiError {
    if (!o || typeof o != "object") {
        return false;
    }

    return Object.keys(o).every(k => ["message", "code"].includes(k));
}

export default ApiError;
