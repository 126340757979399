const en = {
    "c-form-reset.label.password-new": "New password",
    "c-form-reset.placeholder.password-new": "Enter your new password",
    "c-form-reset.label.password-new-repeated": "Confirm new password",
    "c-form-reset.placeholder.password-new-repeated": "Enter your password again",
    "c-form-reset.password-not-match": "Passwords do not match!",
    "c-form-login.button.text": "Log in without TeamSystem ID",
    "c-form-login.password-forgotten": "Forgot password?",
    "c-form-login.retrieve-credentials": "Recover my credentials",
    "c-form-login.sso.insert-credential":
        "Enter the credentials you used to access TS Digital relating to the TeamSystem ID user to be connected ",
    "c-form-login.ssoaccess.text": "Log in",
    "c-form-login.ssoconnect.text": "Connect",
    "c-form-sso.login": "Log in with TeamSystem ID",
    "c-login.divider": "or",
    "c-login.signup-question": "Don't have an account yet?",
    "c-login.ts-id": "Log in using your unique TeamSystem account",
    "c-registry-user-tech.login.error.generic":
        "An error occurred in the credential checking process. Please try again later.",
    "c-registry-user-tech.login.error.notValid":
        "The credentials entered are not valid. Check ID and Secret and try again.",
    "c-retrieve-credentials.company-data-step.recover-credentials-button": "Recover credentials",
    "c-retrieve-credentials.company-data-step.taxId-placeholder": "Enter your tax code",
    "c-retrieve-credentials.company-data-step.title":
        "Enter the tax code, VAT number and country associated with the account",
    "c-retrieve-credentials.company-data-step.vatNumber-placeholder": "Enter your VAT number",
    "c-retrieve-credentials.tech-keys-step.title":
        "To recover the email of one of your users, enter the ID of a technical key you created and the secret associated with it.",
    "c-retrieve-credentials.tech-keys-step.techId-label": "Tech Key ID",
    "c-retrieve-credentials.tech-keys-step.techId-placeholder": "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
    "c-retrieve-credentials.tech-keys-step.techSecret-label": "Secret",
    "c-retrieve-credentials.tech-keys-step.techSecret-placeholder": "Enter the secret",
    "c-retrieve-credentials.user-retrieved-step.title":
        "The procedure was successful! We have identified the email associated with the data entered:",
    "c-retrieve-credentials.user-retrieved-step.back-to-login-button": "Return to the login page",
    "c-user-connect.tsid.label": "TeamSystem ID",
    "c-logged-user.success-feedback": "TeamSystem ID linked",
    "forgot-password.title": "Forgot your password?",
    "forgot-password.title-success": "Password reset",
    "forgot-password.title-error": "Invalid email",
    "forgot-password.button-continue": "Return to login",
    "forgot-password.button-error": "Retry the request",
    "reset-password.title": "Create new password",
    "reset-password.tittle-success": "your password has been successfully changed!",
    "reset-password.subtittle-success":
        "Congratulations, your password has been successfully changed. Return to login to access the platform.",
    "reset-password.button.continue": "Return to login",
    "gdpr.login-form.invalid-email": "Please enter a valid email",
    "general.annulla": "Cancel",
    "general.codice-fiscale": "Tax ID code",
    "general.continue": "Continue",
    "general.country": "Nation",
    "general.email": "Email",
    "general.form.field-required": "Required field",
    "general.goBack": "Go back",
    "general.password": "Password",
    "general.PIVA": "VAT number",
    "general.privacy.disclaimer": "Privacy disclaimer",
    "general.retry": "Try again",
    "general.see-more": "Find out more",
    "general.selectOne": "Select",
    "general.important-notice": "Important Notice!",
    "general.confirm": "Confirm",
    "general.error": "Error",
    "general.attention": "Attention!",
    "general.login-with-ts-id": "Log in with TeamSystem ID",
    "general.remind-me-later": "Remind me next time you log in",
    "login.login-or-signup-with-ts-id": "Log in or register with TeamSystem ID",
    "login.login-or-signup-without-ts-id": "Log in or register without TeamSystem ID",
    "login.login-to-teamsystem-digital": "Log in to TeamSystem Digital",
    "login.login-tsid-info": " Enter your TeamSystem ID (TSID) and Password to log in to TeamSystem Digital.",
    "login.login-with-credential":
        "If you don't have a TeamSystem ID Enter your credentials to log in to TeamSystem Digital.",
    "login.retrieve-your-email": "Recover your Email",
    "login.signup-to-teamsystem-id": "Don't have your TeamSystem ID yet? Register now",
    "referrer-gate.whitelist-retrieval-error": "There was an error accessing the page, please try again later.",
    "referrer-gate.unauthorized-referrer": "Unable to complete login.",
    "v-forgot-password-message":
        "Enter the email address of the user whose password you don't remember, we will send you an email so you can reset it.",
    "v-forgot-password-error-captcha": "Captcha Incorrect, try again",
    "v-forgot-password-error-invalid-mail": "Invalid email, try again",
    "v-forgot-password-error-not-on-system-mail": "Email not registered",
    "v-forgot-password-success": "Your password has been successfully reset, check your inbox.",
    "v-forgot-password-error":
        "The email is not registered on our system, please try the password recovery process again.",
    "v-form-reset-error": "Error resetting password",
    "v-reset-password-message": "Enter the new password to use to log in.",
    "v-retrieve-credentials.company-does-not-exist": "The specified company does not exist",
    "v-retrieve-credentials.retrieval-error":
        "An error occurred in the credential recovery process. Please try again later",
    "v-retrieve-credentials.unauthorized":
        "The selected company does not appear to be connected to this application user",
    "v-first-access.modal-title": "You have logged in for the first time with TeamSystem ID",
    "v-first-access.title": "Do you have a TeamSystem digital account that you want to connect?",
    "v-first-access.subtitle":
        "Connect it now to view your account data and continue logging in with your TeamSystem ID.",
    "v-first-access.connect-account-option.confirm": "Yes, I want to link my TS Digital account to the TeamSystem ID",
    "v-first-access.connect-account-option.decline": "No, I want to set up a new account",
    "v-connect-tsid-to-digital-account.modal-title": "Link your TeamSystem ID to your TS Digital account",
    "v-connect-tsid-to-digital-account.connect": "Log in and link accounts",
    "v-connect-tsid-to-digital-account.description":
        "Log in using your personal credentials to create the connection and access your account with the unique TeamSystem ID user.",
    "v-connect-account-feedback.connecting": "We are linking your TeamSystem ID to your TeamSystem Digital account.",
    "v-connect-account-feedback.connecting.long-operation":
        "This operation may take a few minutes. If you are not automatically redirected to {appName} <link>contact support.</link>",
    "v-connect-tsid-to-digital-account.local-user.description":
        "There is a TS Digital account associated with the same email as the TeamSystem ID. Enter the password you usually use to log in to create the connection.",
    "v-connect-account-feedback.disclamer":
        "We are redirecting you to {appName}. This operation may take a few moments. If you are not automatically redirected <link>click here</link>.",
    "v-connect-account-feedback.mail-change.disclaimer":
        "<b>Info:</b> you will receive all communications from TS Digital and the applications in use to the inbox of the TeamSystem ID email you connected.",
    "v-connect-account-feedback.success.title": "Connection successful",
    "v-connect-account-feedback.success.description":
        "From today you can access your TS Digital account with your TeamSystem ID.",
    "v-connect-account-feedback.loading.title": "Connecting...",
    "v-connect-account-feedback.loading.description": "We are linking your TeamSystem ID to your TS Digital account.",
    "v-connect-account-feedback.error.title": "Something went wrong! The connection was unsuccessful",
    "v-connect-account-feedback.error.description":
        "To start the connection process again, click Retry. If you cannot resolve the problem, contact support.",
    "v-connect-account-feedback.error.back-to-login": "Return to login",
    "v-connect-account-feedback.error.user-already-connected.title": "The connection was unsuccessful",
    "v-connect-account-feedback.error.user-already-connected.description":
        "The personal credentials you entered are already linked to another TeamSystem ID. If you don't remember which TeamSystem ID you have already connected, go back to login, log in with your personal credentials and visit the “Teamsystem ID” section of your profile.",
    "v-connect-account-feedback.error.wrong-credentials.title": "The connection was unsuccessful",
    "v-connect-account-feedback.error.wrong-credentials.description":
        "The email or password entered to create the link between the TeamSystem ID and your TS Digital account is incorrect, please try entering it again.",
    "v-connect-account-feedback.error.user-already-connected-to-tsid.description":
        "The TeamSystem ID you signed in with is already linked to another TeamSystem Digital account. You can:",
    "v-connect-account-feedback.error.user-already-connected-to-tsid.description.first":
        "Link another TeamSystem ID to this account.",
    "v-connect-account-feedback.error.user-already-connected-to-tsid.description.second":
        "Log in to the other TeamSystem Digital account and contact support asking to disconnect the reference TeamSystem ID. Once disconnected, try to connect again.",
    "v-connect-account-feedback.error.user-already-connected-to-tsid.connect-another-user":
        "Link another TeamSystem ID",
    "v-connect-digital-account-to-tsid.modal-title": "Link a TeamSystem ID to your TS Digital account",
    "v-connect-digital-account-to-tsid.description.first-sentence": "Log in to TeamSystem to connect.",
    "v-connect-digital-account-to-tsid.description.second-sentence":
        "You will be redirected to the login page with your TeamSystem ID where you can log in. Once you have linked your TeamSystem ID with your TS Digital account you will be able to access all TeamSystem services with a single account.",
    "v-connect-digital-account-to-tsid.login-and-connect-button": "Log in and connect TeamSystem ID",
    "v-have-already-tsid-account.description.attention":
        "From <b>30/11/2022</b> it will be possible to access the {appName} application <b>only with the TeamSystem ID</b>.",
    "v-have-already-tsid-account.description.have-already-tsid": "Already have a TeamSystem ID?",
    "v-have-already-tsid-account.description.login-with-tsid":
        "<b>Log in</b> with your TeamSystem ID <b>to link it</b> to your account.",
    "v-have-already-tsid-account.title.dont-have-tsid": "Don't have a TeamSystem ID?",
    "v-have-already-tsid-account.title.dont-have-tsid.first": "<b>Register</b> with the TeamSystem ID.",
    "v-have-already-tsid-account.title.dont-have-tsid.second":
        "<b>Log in for the first time</b> with your new TeamSystem ID to link it to your TeamSystem Digital account.",
    "v-have-already-tsid-account.title.dont-have-tsid.login-or-signup": "Log in or register for TeamSystem ID",
    "v-have-already-tsid-account.title.dont-have-tsid.remember-me-later": "Remind me next time you log in",
    "v-important-notice.description.from":
        "From <b>30/11/2022</b> it will be possible to access the {appName} application only with the TeamSystem ID.",
    "v-important-notice.description.personal-credential-not-valid":
        "You already have a TeamSystem ID connected to your account with this email <b>{tsId}</b> so you won't have to worry about anything, but remember that <b>the personal credentials you just logged in with will no longer be valid .</b>",
    "v-important-notice.button.ok": "Ok, I understand",
    "v-invite-feedback.access-denied": "Access not permitted",
    "v-invite-feedback.different-emails":
        "The Teamsystem ID you are trying to log in with (<b>{loggedEmail}</b>) is invalid. Remember to use a Teamsystem ID with the same email as the invitation, i.e. <b>{inviteEmail}</b>. If you do not have a Teamsystem ID with the indicated email, to access TS Digital you will need to register another one specifically.",
    "complete-invitation.failed": "Oops, there was an error",
    "complete-invitation.succeeded": "Great, you have completed the invitation process",
    "complete-invitation.youAreAboutToBeRedirected": "Soon you will find yourself on the portal!",
    "complete-invitation.ifRedirectDoesNotWorkClickHere":
        "If you are still here in a few seconds, press here to go to the portal!",
    "v-login.unauthorized-message":
        "<p>Warning: the email or password you entered is incorrect.</p><p>Check that you have not mistakenly entered the email and password associated with your TeamSystem ID. To log in with your TeamSystem ID, click on the appropriate button at the top of the login page.</p>",
    "v-login.generic-error": "Oops, there was an error.",
    "e-password-length": "The password must contain at least 8 characters",
    "e-password-uppercase": "The password must contain at least one capital letter",
    "e-password-lowercase": "The password must contain at least one lowercase letter",
    "e-password-number": "The password must contain at least one number",
    "e-password-special-char": "The password must contain at least one special character",
    "v-already-connected-to-tsid.from-date":
        "From <b>December 1, 2022</b> you can only access TeamSystem Digital with your TeamSystem ID.",
    "v-already-connected-to-tsid.ts-id-email":
        "<b>You already have a TeamSystem ID linked to your account</b> so you don't have to worry about anything. The email associated with your TeamSystem ID is: <b>{email}</b>.",
    "v-already-connected-to-tsid.forgot-password":
        "If you don't remember your password, retrieve it from the TeamSystem ID login page or simply log in.",
    "v-already-connected-to-tsid.info-box":
        "he process of adopting TeamSystem ID as an authentication method has begun. You may notice that some of your colleagues are still logging in without TeamSystem ID: this is not an error, we will ask all users to make the switch in the next few days.",
    "v-not-connected-to-tsid.from-date":
        "From <b>December 1, 2022</b> you can only access TeamSystem Digital with your TeamSystem ID.",
    "v-not-connected-to-tsid.already-have-a-tsid": "Already have a TeamSystem ID?",
    "v-not-connected-to-tsid.login-and-connect":
        "<b>Log in</b> with your TeamSystem ID <b>to link it</b> to your account.",
    "v-not-connected-to-tsid.dont-have-a-tsid": "Don't have a TeamSystem ID?",
    "v-not-connected-to-tsid.title.dont-have-a-tsid.first": "<b>Register</b> with the TeamSystem ID.",
    "v-not-connected-to-tsid.title.dont-have-a-tsid.second":
        "<b>Log in for the first time</b> with your new TeamSystem ID to link it to your TeamSystem Digital account.",
    "v-not-connected-to-tsid.info-box.forceTsIdLogin":
        "The process of adopting TeamSystem ID as an authentication method has begun. You may notice that some of your colleagues are still logging in without TeamSystem ID: this is not an error, we will ask all users to make the switch in the next few days.",
    "v-not-connected-to-tsid.info-box.notForceTsIdLogin":
        "The TeamSystem ID adoption process has begun. Remember that the credentials you usually use to log in will soon no longer be valid, so we invite you to connect the TeamSystem ID to your account as soon as possible, it will only take a few minutes!"
};

export default en;
