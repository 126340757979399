import React from "react";
import { Button, TextField, Typography } from "@vapor/react-material";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import LoginModal from "../../../components/general/login-modal";
import LoginModalContainer from "../../../components/login/login-modal-container";
import ReCaptcha from "./ReCaptcha";
import { ButtonsContainer, FormItemContainer } from "./styled";
import { FormEvent } from "react";

type ForgotPasswordFormProps = {
    email: string;
    setEmail: (email: string) => void;
    emailError: string;
    isEmailValid: boolean;
    handleSubmit: (e: FormEvent) => void;
    captcha: string;
    onCheckReCaptcha: (token: string | null) => void;
    recaptchaError: string;
};
function ForgotPasswordForm({
    email,
    setEmail,
    emailError,
    isEmailValid,
    handleSubmit,
    captcha,
    onCheckReCaptcha,
    recaptchaError
}: ForgotPasswordFormProps) {
    const intl = useIntl();
    const navigate = useNavigate();

    return (
        <LoginModalContainer>
            <LoginModal title={<FormattedMessage id="forgot-password.title" />}>
                <Typography sx={{ mb: 3 }} color="primary.textSubduedColor" variant="bodyLarge">
                    <FormattedMessage id="v-forgot-password-message" />
                </Typography>
                <form onSubmit={handleSubmit}>
                    <FormItemContainer>
                        <TextField
                            label={<FormattedMessage id="general.email" />}
                            inputProps={{ id: "email" }}
                            error={!isEmailValid}
                            helperText={emailError}
                            value={email}
                            placeholder={intl.formatMessage({
                                id: "general.email"
                            })}
                            onChange={event => setEmail(event.target.value)}
                        />
                    </FormItemContainer>
                    <ReCaptcha captcha={captcha} onChange={onCheckReCaptcha} error={recaptchaError} />
                    <FormItemContainer>
                        <ButtonsContainer>
                            <Button onClick={() => navigate(-1)} variant="outlined">
                                {intl.formatMessage({
                                    id: "general.annulla"
                                })}
                            </Button>
                            <Button type="submit" variant="contained">
                                {intl.formatMessage({
                                    id: "general.continue"
                                })}
                            </Button>
                        </ButtonsContainer>
                    </FormItemContainer>
                </form>
            </LoginModal>
        </LoginModalContainer>
    );
}

export default ForgotPasswordForm;
