import cuid from "@paralleldrive/cuid2";
import axios, { AxiosResponse } from "axios";
import flatten from "flat";
import { v4 as uuid } from "uuid";

import { displayErrorToast, displaySuccessToast } from "../actions/overflowNotification";
import ApiError, { isApiError } from "../types/ApiError";

async function performCall<T>(
    url: string,
    token: string,
    method: string,
    urlParams?: object | null,
    body?: object | null,
    userId?: string,
    itemId?: string,
    userLanguage: string = "it-IT"
): Promise<AxiosResponse<T | ApiError> & { ok: boolean }> {
    let queryString = urlParams ? getQueryString(urlParams) : "";
    queryString = queryString !== "" ? `?${queryString}` : "";

    const headers: { [key: string]: string } = {
        Authorization: `Bearer ${token ? token : ""}`,
        "Content-Type": "application/json",
        "X-App-Name": "PORTALE",
        "X-App-Version": "1.0",
        "X-Request-Id": cuid.createId(),
        "X-Correlation-Id": uuid()
    };

    if (userId) {
        headers["X-User-Id"] = userId;
    }

    if (itemId) {
        headers["X-Item-Id"] = itemId;
    }

    if (userLanguage) {
        headers["Accept-Language"] = userLanguage;
    }

    return await axios({
        url: `${url}${queryString}`,
        method: method,
        data: body && JSON.stringify(body),
        headers
    })
        .then(response => {
            return { ...response, ok: true };
        })
        .catch(error => {
            return { ...error.response, ok: false };
        });
}

export async function restApiCall<T>(
    url: string,
    auth: { securityToken?: string } | null,
    method: string,
    urlParams: object | null,
    body: any,
    dispatch?: any,
    userProfile: { id?: string; language?: string } = {},
    itemId: string | null = null,
    displayError = false,
    displaySuccess = false
): Promise<T> {
    let response = await performCall(
        url,
        auth?.securityToken || "",
        method,
        urlParams,
        body,
        userProfile.id,
        itemId || "",
        userProfile.language
    );

    if (!response.ok) {
        let errResponse = { status: response.status, message: "" };
        if (isApiError(response.data)) {
            const text = response.data.message;

            if (displayError) {
                dispatch(displayErrorToast(text, "Error"));
            }

            errResponse.message = text;
        }

        throw errResponse;
    }

    if (displaySuccess) {
        dispatch(displaySuccessToast("Operazione correttamente presa in carico", "Success"));
    }

    return response.data as T;
}

export function headRestCall<T>(
    url: string,
    auth: { securityToken?: string } | null,
    urlParams: object | null,
    dispatch?: any,
    userProfile: { id?: string; language?: string } = {},
    itemId: string | null = null,
    displayError = false,
    displaySuccess = false
) {
    return restApiCall<T>(
        url,
        auth,
        "HEAD",
        urlParams,
        null,
        dispatch,
        userProfile,
        itemId,
        displayError,
        displaySuccess
    );
}

export function getRestCall<T>(
    url: string,
    auth: { securityToken?: string } | null,
    urlParams: object | null,
    dispatch?: any,
    userProfile: { id?: string; language?: string } = {},
    itemId: string | null = null,
    displayError = false,
    displaySuccess = false
) {
    return restApiCall<T>(
        url,
        auth,
        "GET",
        urlParams,
        null,
        dispatch,
        userProfile,
        itemId,
        displayError,
        displaySuccess
    );
}

export function postRestCall<T>(
    url: string,
    auth: { securityToken?: string } | null,
    urlParams: object | null,
    body: any,
    dispatch?: any,
    userProfile: { id?: string; language?: string } = {},
    itemId: string | null = null,
    displayError = false,
    displaySuccess = false
) {
    return restApiCall<T>(
        url,
        auth,
        "POST",
        urlParams,
        body,
        dispatch,
        userProfile,
        itemId,
        displayError,
        displaySuccess
    );
}

export function deleteRestCall<T>(
    url: string,
    auth: { securityToken?: string } | null,
    urlParams: object | null,
    body: any,
    dispatch?: any,
    userProfile: { id?: string; language?: string } = {},
    itemId: string | null = null,
    displayError = false,
    displaySuccess = false
) {
    return restApiCall<T>(
        url,
        auth,
        "DELETE",
        urlParams,
        body,
        dispatch,
        userProfile,
        itemId,
        displayError,
        displaySuccess
    );
}

export function patchRestCall<T>(
    url: string,
    auth: { securityToken?: string } | null,
    urlParams: object | null,
    body: any,
    dispatch?: any,
    userProfile: { id?: string; language?: string } = {},
    itemId: string | null = null,
    displayError = true
) {
    return restApiCall<T>(url, auth, "PATCH", urlParams, body, dispatch, userProfile, itemId, displayError);
}

export function putRestCall<T>(
    url: string,
    auth: { securityToken?: string } | null,
    urlParams: object | null,
    body: any,
    dispatch?: any,
    userProfile: { id?: string; language?: string } = {},
    itemId: string | null = null,
    displayError = true
) {
    return restApiCall<T>(url, auth, "PUT", urlParams, body, dispatch, userProfile, itemId, displayError);
}

/**
 * Trasform a nested object in a query string
 * @param params nested object
 */
function getQueryString(params: { [key: string]: any }) {
    return params
        ? Object.entries(flatten<{ [key: string]: any }, { [key: string]: any }>(params, { safe: true }))
              .filter(([_, value]) => value !== undefined && value !== null)
              .map(key => `${key[0]}=${encodeURIComponent(key[1] + "")}`)
              .join("&")
        : "";
}
