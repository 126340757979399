import React from "react";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";

import LoggedUser from "../../../../components/general/logged-user";
import Notification from "../../../../components/general/notification";
import { Description, FullWidthButton, LinkButton, LoggedUserContainer, SuccessIcon, Title } from "./styled";

type SuccessContentProps = {
    appName: string;
    digitalEmail: string;
    firstName: string;
    lastName: string;
    onContinue: () => void;
    tsIdEmail: string;
};
const SuccessContent = ({ appName, digitalEmail, firstName, lastName, onContinue, tsIdEmail }: SuccessContentProps) => {
    return (
        <div>
            <SuccessIcon>
                <FontAwesomeIcon icon={faCheckCircle} />
            </SuccessIcon>
            <Title>
                <FormattedMessage id="v-connect-account-feedback.success.title" />
            </Title>
            <Description>
                <FormattedMessage id="v-connect-account-feedback.success.description" />
            </Description>
            <LoggedUserContainer>
                <LoggedUser name={firstName} surname={lastName} email={tsIdEmail} showFeedback={true} />
            </LoggedUserContainer>
            {tsIdEmail === digitalEmail && (
                <Description>
                    <FormattedMessage
                        id="v-connect-account-feedback.disclamer"
                        values={{
                            link: chunks => <LinkButton onClick={onContinue}>{chunks}</LinkButton>,
                            appName: appName || "applicazione"
                        }}
                    />
                </Description>
            )}
            {tsIdEmail !== digitalEmail && (
                <>
                    <Notification
                        message={
                            <FormattedMessage
                                id="v-connect-account-feedback.mail-change.disclaimer"
                                values={{ b: chunks => <b>{chunks}</b> }}
                            />
                        }
                    />
                    <FullWidthButton onClick={onContinue} variant="contained">
                        <FormattedMessage id="v-important-notice.button.ok" />
                    </FullWidthButton>
                </>
            )}
        </div>
    );
};

export default SuccessContent;
