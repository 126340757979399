import React, { ReactNode } from "react";

import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@vapor/react-material";
import { FormattedMessage } from "react-intl";

import { ButtonsContainer, Description, ErrorIcon, Title } from "./styled";

type ErrorContentProps = {
    description: string | ReactNode;
    onRetry: () => void;
    retryButtonMessage: string;
    title: string | ReactNode;
    onBackToLogin: () => void;
};
const ErrorContent = ({ description, onRetry, retryButtonMessage, title, onBackToLogin }: ErrorContentProps) => {
    return (
        <div>
            <ErrorIcon>
                <FontAwesomeIcon icon={faExclamationTriangle} />
            </ErrorIcon>
            <Title>{title}</Title>
            <Description>{description}</Description>
            <ButtonsContainer>
                <Button onClick={() => onRetry()} variant="contained">
                    <FormattedMessage id={retryButtonMessage} />
                </Button>
                <Button variant="text" onClick={() => onBackToLogin()}>
                    <FormattedMessage id="v-connect-account-feedback.error.back-to-login" />
                </Button>
            </ButtonsContainer>
        </div>
    );
};

export default ErrorContent;
