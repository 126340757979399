import { API_URL } from "../config";

function jumpToApp(app: string, deepLink?: string | null) {
    const query = {
        serviceId: app,
        deepLink
    };

    const queryString = Object.entries(query)
        .filter(([_, value]) => value)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");

    window.location.href = `${API_URL}/jump?${queryString}`;
}

export default jumpToApp;
