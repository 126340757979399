import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";

import { Button, TextField } from "@vapor/react-material";
import { EmailValidator } from "commons-validator-js";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { connectTsId } from "../../../actions/tsid-v3";
import LoadingButton from "../../../components/general/loading-button";
import LoginModal from "../../../components/general/login-modal";
import LoginModalContainer from "../../../components/login/login-modal-container";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import tsIdV3Logout from "../../../utils/tsid-v3-logout";
import { ButtonsContainer, Description, InputAction, InputContainer } from "./styled";

const cardDescriptions = {
    canChooseUser: "v-connect-tsid-to-digital-account.description",
    userChoiceLocked: "v-connect-tsid-to-digital-account.local-user.description"
};

const ConnectTSIdToDigitalAccount = () => {
    const connectTsIdStatus = useAppSelector(state => state.tsIdV3.connectTsId.status);
    const idToken = useAppSelector(state => state.tsIdV3.login.loginResult.idToken) || "notset";
    const loginOptions = useAppSelector(state => state.loginOptions);
    const localUser = useAppSelector(state => state.tsIdV3.checkTsIdConnected.result.localUser);
    const tsIdEmail = useAppSelector(state => state.tsIdV3.login.tokenData.email);
    const tsIdToken = useAppSelector(state => state.tsIdV3.login.loginResult.accessToken) || "notset";

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [email, setEmail] = useState(localUser ? tsIdEmail : null);
    const [emailFieldTouched, setEmailFieldTouched] = useState<boolean>(false);
    const [password, setPassword] = useState<string | null>(null);
    const [passwordFieldTouched, setPasswordFieldTouched] = useState<boolean>(false);

    useEffect(() => {
        if (connectTsIdStatus.started) {
            navigate("/connectAccountFeedback");
        }
    }, [navigate, connectTsIdStatus]);

    const onBack = useCallback(
        (e: SyntheticEvent) => {
            e.preventDefault();
            e.stopPropagation();

            if (localUser) {
                tsIdV3Logout(idToken, loginOptions);
            } else {
                navigate("/firstAccess");
            }
        },
        [navigate, idToken, localUser, loginOptions]
    );

    const isEmailValid = email && new EmailValidator().isValid(email);

    let emailHelperText;
    if (!emailFieldTouched || isEmailValid) {
        emailHelperText = "";
    } else if (!email) {
        emailHelperText = "Campo obbligatorio";
    } else {
        emailHelperText = "Formato mail non valido";
    }

    return (
        <LoginModalContainer>
            <LoginModal title={<FormattedMessage id="v-connect-tsid-to-digital-account.modal-title" />}>
                <Description>
                    <FormattedMessage
                        id={localUser ? cardDescriptions.userChoiceLocked : cardDescriptions.canChooseUser}
                    />
                </Description>
                <form>
                    <InputContainer>
                        <TextField
                            name="email"
                            label="Email"
                            required={true}
                            value={email || ""}
                            onBlur={() => setEmailFieldTouched(true)}
                            onChange={e => setEmail(e.target.value)}
                            placeholder="Inserisci email"
                            error={emailFieldTouched && !isEmailValid}
                            helperText={emailHelperText}
                            type="email"
                            disabled={localUser}
                        />
                    </InputContainer>
                    <InputContainer>
                        <TextField
                            name="password"
                            label="Password"
                            required={true}
                            value={password || ""}
                            onBlur={() => setPasswordFieldTouched(true)}
                            onChange={e => setPassword(e.target.value)}
                            placeholder="Inserisci password"
                            error={passwordFieldTouched && !password}
                            helperText={!passwordFieldTouched || password ? "" : "Campo obbligatorio"}
                            type="password"
                        />
                        <InputAction onClick={() => navigate("/forgotPassword")}>Password dimenticata?</InputAction>
                    </InputContainer>
                    <ButtonsContainer>
                        <LoadingButton
                            loading={connectTsIdStatus.started}
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (!isEmailValid || !password) {
                                    setEmailFieldTouched(true);
                                    setPasswordFieldTouched(true);
                                } else {
                                    dispatch(connectTsId({ userId: email, password, tsIdToken }));
                                }
                            }}
                        >
                            <FormattedMessage id="v-connect-tsid-to-digital-account.connect" />
                        </LoadingButton>
                        <Button variant="text" onClick={onBack}>
                            <FormattedMessage id="general.annulla" />
                        </Button>
                    </ButtonsContainer>
                </form>
            </LoginModal>
        </LoginModalContainer>
    );
};

export default ConnectTSIdToDigitalAccount;
