import styled from "styled-components";

const color = {
    informative: (props: any) => props.theme.primary.textTitleColor,
    check: (props: any) => props.theme.primary.successed,
    warning: (props: any) => props.theme.primary.baseWarningColor,
    error: (props: any) => props.theme.primary.baseCriticalColor
};

export const Container = styled.div<{ closable: boolean; variant: "informative" | "check" | "warning" | "error" }>`
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 4px;
    border-left-width: 1px;
    border-color: ${props => color[props.variant]};
    border-style: solid;
    background-color: white;
    display: grid;
    grid-template-columns: ${props => (props.closable ? "64px 1fr 64px" : "64px 1fr")};
`;

export const IconContainer = styled.div<{ variant: "informative" | "check" | "warning" | "error" }>`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    color: ${props => color[props.variant]};
    height: 64px;
`;

export const MessageContainer = styled.div`
    padding-top: 18px;
    padding-bottom: 18px;
`;

export const CloseButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: ${props => props.theme.typography.body.color};
    height: 64px;
    cursor: pointer;

    &:hover {
        color: ${props => props.theme.primary.interactiveDefault};
    }
`;
