import styled from "styled-components";

export const FormItemContainer = styled.div`
    position: relative;
    margin-bottom: 1.5rem;
`;

export const ButtonsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin-top: 3.5rem;
    margin-bottom: 0.5rem;

    & > * {
        width: 100%;
    }
`;
