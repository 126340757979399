import { createAction } from "@reduxjs/toolkit";

export const SHOW_OVERFLOW = "SHOW_OVERFLOW";
export const HIDE_OVERFLOW = "HIDE_OVERFLOW";

export const displayInfoToast = createAction(SHOW_OVERFLOW, getToastPayload("info"));
export const displaySuccessToast = createAction(SHOW_OVERFLOW, getToastPayload("success"));
export const displayErrorToast = createAction(SHOW_OVERFLOW, getToastPayload("error"));
export const displayWarningToast = createAction(SHOW_OVERFLOW, getToastPayload("warning"));

function getToastPayload(type: string) {
    return (message: string, title: string) => {
        return {
            payload: {
                type,
                title,
                message
            }
        };
    };
}

export const hideToast = createAction(HIDE_OVERFLOW);
