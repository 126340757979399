import { createReducer } from "@reduxjs/toolkit";
import { setLoginOptions, setLoginOptionsReset } from "../actions/login-options";

const defaultLoginOptions = {
    appName: "di origine",
    loginMethod: "TSID"
};

export const loginOptions = createReducer(defaultLoginOptions, builder => {
    builder
        .addCase(setLoginOptions, (state, action) => {
            state.appName = action.payload.appName;
            state.loginMethod = action.payload.loginMethod;
        })
        .addCase(setLoginOptionsReset, () => defaultLoginOptions)
        .addDefaultCase(state => state);
});

export default loginOptions;
