import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { TextField, Typography } from "@vapor/react-material";

import LoginModal from "../../../../components/general/login-modal";
import LoginModalContainer from "../../../../components/login/login-modal-container";
import LoadingButton from "../../../../components/general/loading-button";
import { FormItemContainer } from "./styled";

type ResetFormProps = {
    onSubmit: (password: string, repeatedPassword: string) => void;
    onProgress: boolean;
    error: string | null;
};
const ResetForm = ({ onSubmit, onProgress, error }: ResetFormProps) => {
    const intl = useIntl();
    const [password, setPassword] = useState<string>("");
    const [repeatedPassword, setRepeatedPassword] = useState<string>("");

    return (
        <LoginModalContainer>
            <LoginModal title={<FormattedMessage id="reset-password.title" />}>
                <Typography sx={{ mb: 3 }} color="primary.textSubduedColor" variant="bodyLarge">
                    <FormattedMessage id="v-reset-password-message" />
                </Typography>
                <form
                    onSubmit={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        onSubmit(password, repeatedPassword);
                    }}
                >
                    <FormItemContainer>
                        <TextField
                            label={<FormattedMessage id="c-form-reset.label.password-new" />}
                            error={!!error}
                            inputProps={{
                                id: "password-new"
                            }}
                            type="password"
                            placeholder={intl.formatMessage({
                                id: "c-form-reset.placeholder.password-new"
                            })}
                            onChange={e => setPassword(e.target.value)}
                        />
                    </FormItemContainer>
                    <FormItemContainer>
                        <TextField
                            label={<FormattedMessage id="c-form-reset.label.password-new-repeated" />}
                            inputProps={{
                                id: "password-new-repeated"
                            }}
                            error={!!error}
                            helperText={error || undefined}
                            type="password"
                            placeholder={intl.formatMessage({
                                id: "c-form-reset.placeholder.password-new-repeated"
                            })}
                            onChange={e => setRepeatedPassword(e.target.value)}
                        />
                    </FormItemContainer>
                    <LoadingButton style={{ marginTop: "60px" }} fullWidth={true} loading={onProgress} type="submit">
                        {intl.formatMessage({
                            id: "general.confirm"
                        })}
                    </LoadingButton>
                </form>
            </LoginModal>
        </LoginModalContainer>
    );
};

export default ResetForm;
