import React, { ReactNode } from "react";

import { TS_DIGITAL_IMAGES } from "../../../config";
import { Container, Logo, LogoContainer } from "./styled";
import { Typography } from "@vapor/react-material";

type LoginModalProps = {
    children?: any;
    title?: ReactNode;
};

const LoginModal = ({ children, title }: LoginModalProps) => {
    return (
        children && (
            <Container>
                <LogoContainer>
                    <Logo src={`${TS_DIGITAL_IMAGES}logo-with-ts-color.svg`} />
                </LogoContainer>
                {title && (
                    <Typography sx={{ mb: 2 }} variant="titleLarge">
                        {title}
                    </Typography>
                )}
                {children}
            </Container>
        )
    );
};

export default LoginModal;
