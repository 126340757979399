import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Typography } from "@vapor/react-material";

import LoginModal from "../../../../components/general/login-modal";
import LoginModalContainer from "../../../../components/login/login-modal-container";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { SuccessIcon } from "../../../sso/connect-account-feedback/success/styled";
import { useNavigate } from "react-router-dom";

const ResetSuccess = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    return (
        <LoginModalContainer>
            <LoginModal
                title={
                    <>
                        <SuccessIcon>
                            <FontAwesomeIcon icon={faCheckCircle} />
                        </SuccessIcon>
                        <div style={{ textAlign: "center" }}>
                            {intl.formatMessage({
                                id: "reset-password.tittle-success"
                            })}
                        </div>
                    </>
                }
            >
                <Typography sx={{ mb: 3 }} align="center" color="primary.textSubduedColor" variant="bodyLarge">
                    <FormattedMessage id="reset-password.subtittle-success" />
                </Typography>
                <Button onClick={() => navigate("/")} style={{ marginTop: "150px" }} variant="contained">
                    <FormattedMessage id="reset-password.button.continue" />
                </Button>
            </LoginModal>
        </LoginModalContainer>
    );
};

export default ResetSuccess;
