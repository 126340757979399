import React, { useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { connectTsIdReset } from "../../../actions/tsid-v3";

import LoginModal from "../../../components/general/login-modal";
import LoginModalContainer from "../../../components/login/login-modal-container";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { sendLoginCompleteEvent } from "../../../utils/send-login-complete-event";
import tsIdV3Logout from "../../../utils/tsid-v3-logout";
import ErrorContent from "./error";
import LoadingContent from "./loading";
import SuccessContent from "./success";

const ConnectAccountFeedback = () => {
    const callbackState = useAppSelector(state => state.tsIdV3.callbackState) || "notset";
    const connectedUser = useAppSelector(state => state.tsIdV3.connectTsId.payload.userId) || "notset";
    const connectionStatus = useAppSelector(state => state.tsIdV3.connectTsId.status) || "notset";
    const mode = useAppSelector(state => state.tsIdV3.callbackState.mode) || "notset";
    const referrer = useAppSelector(state => state.tsIdV3.callbackState.originalReferrer) || "notset";
    const refreshToken = useAppSelector(state => state.tsIdV3.login.loginResult.refreshToken) || "notset";
    const idToken = useAppSelector(state => state.tsIdV3.login.loginResult.idToken) || "notset";
    const token = useAppSelector(state => state.tsIdV3.login.loginResult.accessToken) || "notset";
    const tokenData = useAppSelector(state => state.tsIdV3.login.tokenData);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const completeLogin = useCallback(() => {
        sendLoginCompleteEvent(token, refreshToken, idToken, referrer, mode);
    }, [token, refreshToken, idToken, mode, referrer]);

    useEffect(() => {
        if (connectionStatus.ended && !connectionStatus.error && connectedUser === tokenData.email) {
            setTimeout(() => {
                completeLogin();
            }, 2000);
        }
    }, [completeLogin, connectedUser, connectionStatus, tokenData.email]);

    return (
        <LoginModalContainer>
            <LoginModal>
                {connectionStatus.started && (
                    <LoadingContent
                        appName={callbackState.appName || "notset"}
                        firstName={tokenData.given_name || "notset"}
                        lastName={tokenData.family_name || "notset"}
                        tsIdEmail={tokenData.email || "notset"}
                    />
                )}
                {connectionStatus.ended && (
                    <SuccessContent
                        appName={callbackState.appName || "notset"}
                        digitalEmail={connectedUser}
                        firstName={tokenData.given_name || "notset"}
                        lastName={tokenData.family_name || "notset"}
                        onContinue={completeLogin}
                        tsIdEmail={tokenData.email || "notset"}
                    />
                )}
                {connectionStatus.error && ![401, 409].includes(connectionStatus.errorInfo.status) && (
                    <ErrorContent
                        title={<FormattedMessage id="v-connect-account-feedback.error.title" />}
                        description={<FormattedMessage id="v-connect-account-feedback.error.description" />}
                        retryButtonMessage="general.retry"
                        onRetry={() => {
                            dispatch(connectTsIdReset());
                            navigate("/connectTSIdToDigitalAccount");
                        }}
                        onBackToLogin={() => {
                            tsIdV3Logout(idToken, callbackState);
                        }}
                    />
                )}
                {connectionStatus.error && connectionStatus.errorInfo.status === 401 && (
                    <ErrorContent
                        title={<FormattedMessage id="v-connect-account-feedback.error.wrong-credentials.title" />}
                        description={
                            <FormattedMessage id="v-connect-account-feedback.error.wrong-credentials.description" />
                        }
                        retryButtonMessage="general.retry"
                        onRetry={() => {
                            dispatch(connectTsIdReset());
                            navigate("/connectTSIdToDigitalAccount");
                        }}
                        onBackToLogin={() => {
                            tsIdV3Logout(idToken, callbackState);
                        }}
                    />
                )}
                {connectionStatus.error && connectionStatus.errorInfo.status === 409 && (
                    <ErrorContent
                        title={<FormattedMessage id="v-connect-account-feedback.error.user-already-connected.title" />}
                        description={
                            <FormattedMessage id="v-connect-account-feedback.error.user-already-connected.description" />
                        }
                        retryButtonMessage="general.retry"
                        onRetry={() => {
                            dispatch(connectTsIdReset());
                            navigate("/connectTSIdToDigitalAccount");
                        }}
                        onBackToLogin={() => {
                            tsIdV3Logout(idToken, callbackState);
                        }}
                    />
                )}
            </LoginModal>
        </LoginModalContainer>
    );
};

export default ConnectAccountFeedback;
