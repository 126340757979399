import React, { MouseEventHandler, ReactNode } from "react";

import { Button } from "@vapor/react-material";

import { LoadingIcon } from "./styled";
import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons";

export type LoadingButtonProps = {
    children: ReactNode;
    className?: string;
    disabled?: boolean;
    loading: boolean;
    onClick?: MouseEventHandler;
    size?: "small" | "medium" | "large";
    variant?: "contained" | "outlined" | "text";
    [key: string]: any;
};
/**
 * Renders @vapor/react Button which can enter a "loading" state.
 * While in the loading state, the button is disabled and displays
 * a spinner to the left of the button's text
 */
const LoadingButton = ({
    children,
    className,
    disabled = false,
    loading = false,
    onClick,
    size = "medium",
    variant = "contained",
    ...rest
}: LoadingButtonProps) => {
    return (
        <Button
            className={className}
            disabled={disabled || loading}
            onClick={onClick}
            size={size}
            variant={variant}
            {...rest}
        >
            {loading && <LoadingIcon icon={faCircleNotch} className="fa-spin" />}
            {children}
        </Button>
    );
};

export default LoadingButton;
