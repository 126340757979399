import { createAction } from "@reduxjs/toolkit";

export const SET_LOGIN_OPTIONS = "SET_LOGIN_OPTIONS";
export const SET_LOGIN_OPTIONS_RESET = "SET_LOGIN_OPTIONS_RESET";

export const setLoginOptions = createAction(SET_LOGIN_OPTIONS, (loginOptions: any) => {
    const { appName } = loginOptions;
    return {
        payload: {
            ...loginOptions,
            appName: appName || "di origine"
        }
    };
});

export const setLoginOptionsReset = createAction(SET_LOGIN_OPTIONS_RESET);
