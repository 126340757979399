import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { postRestCall } from "../../utils/rest-api-call";
import { API_CAPTCHA_SIGNUP_URL } from "../../config";
import trimAndLower from "../../utils/trimAndLower";

const forgotPasswordPrefix = "FORGOT_PWD";
export const FORGOT_PWD_RESET = `${forgotPasswordPrefix}/reset`;

export type ForgotPasswordParameters = {
    email: string;
    captchaCode: string;
};
export const forgotPassword = createAsyncThunk(
    forgotPasswordPrefix,
    ({ email, captchaCode }: ForgotPasswordParameters) => {
        email = trimAndLower(email) || "";

        return postRestCall<{ id: string }>(`${API_CAPTCHA_SIGNUP_URL}/forgot-password`, null, null, {
            captchaCode,
            userId: email
        });
    }
);
export const forgotPasswordReset = createAction(FORGOT_PWD_RESET);
