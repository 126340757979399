const it = {
    "c-form-reset.label.password-new": "Nuova password",
    "c-form-reset.placeholder.password-new": "Inserisci la nuova password",
    "c-form-reset.label.password-new-repeated": "Conferma nuova password",
    "c-form-reset.placeholder.password-new-repeated": "Inserisci nuovamente la password",
    "c-form-reset.password-not-match": "Le passwords non corrispondono!",
    "c-form-login.button.text": "Accedi senza TeamSystem ID",
    "c-form-login.password-forgotten": "Password dimenticata?",
    "c-form-login.retrieve-credentials": "Recupera le mie credenziali",
    "c-form-login.sso.insert-credential":
        "Inserisci le credenziali che utilizzavi per accedere ad TS Digital relative all'utente da collegare TeamSystem ID ",
    "c-form-login.ssoaccess.text": "Accedi",
    "c-form-login.ssoconnect.text": "Collega",
    "c-form-sso.login": "Accedi con TeamSystem ID",
    "c-login.divider": "oppure",
    "c-login.signup-question": "Non hai ancora un account?",
    "c-login.ts-id": "Accedi utilizzando l'account unico TeamSystem",
    "c-registry-user-tech.login.error.generic":
        "Si è verificato un errore nel processo di controllo credenziali. Si prega di riprovare più tardi.",
    "c-registry-user-tech.login.error.notValid":
        "Le credenziali inserite non risultano essere valide. Controlla ID e Secret e riprova.",
    "c-retrieve-credentials.company-data-step.recover-credentials-button": "Recupera credenziali",
    "c-retrieve-credentials.company-data-step.taxId-placeholder": "Inserisci il codice fiscale",
    "c-retrieve-credentials.company-data-step.title":
        "Inserisci il Codice fiscale, la Partita IVA e la Nazione associate all'account",
    "c-retrieve-credentials.company-data-step.vatNumber-placeholder": "Inserisci la Partiva IVA",
    "c-retrieve-credentials.tech-keys-step.title":
        "Per recuperare l'email di una tua utenza inserisci l'ID di una chiave tecnica da te creata e il secret ad essa associato.",
    "c-retrieve-credentials.tech-keys-step.techId-label": "ID chiave tecnica",
    "c-retrieve-credentials.tech-keys-step.techId-placeholder": "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
    "c-retrieve-credentials.tech-keys-step.techSecret-label": "Secret",
    "c-retrieve-credentials.tech-keys-step.techSecret-placeholder": "Inserisci il secret",
    "c-retrieve-credentials.user-retrieved-step.title":
        "La procedura è andata a buon fine! Abbiamo individuato l'email associata ai dati inseriti:",
    "c-retrieve-credentials.user-retrieved-step.back-to-login-button": "Torna alla pagina di login",
    "c-user-connect.tsid.label": "TeamSystem ID",
    "c-logged-user.success-feedback": "TeamSystem ID collegato",
    "forgot-password.title": "Hai dimenticato la tua password?",
    "forgot-password.title-success": "Password reimpostata",
    "forgot-password.title-error": "Mail non valida",
    "forgot-password.button-continue": "Torna al login",
    "forgot-password.button-error": "Ritenta la richiesta",
    "reset-password.title": "Crea nuova password",
    "reset-password.tittle-success": "la tua password è stata modificata con successo!",
    "reset-password.subtittle-success":
        "Complimenti, la tua password è stata modificata con successo. Torna al login per accedere alla piattaforma.",
    "reset-password.button.continue": "Torna al login",
    "gdpr.login-form.invalid-email": "Inserisci una mail valida",
    "general.annulla": "Annulla",
    "general.codice-fiscale": "Codice fiscale",
    "general.continue": "Prosegui",
    "general.country": "Nazione",
    "general.email": "Email",
    "general.form.field-required": "Campo obbligatorio",
    "general.goBack": "Torna Indietro",
    "general.password": "Password",
    "general.PIVA": "P. IVA",
    "general.privacy.disclaimer": "Informativa Privacy",
    "general.retry": "Riprova",
    "general.see-more": "Scopri di più",
    "general.selectOne": "Seleziona",
    "general.important-notice": "Avviso importante!",
    "general.confirm": "Conferma",
    "general.error": "Errore",
    "general.attention": "Attenzione!",
    "general.login-with-ts-id": "Accedi con TeamSystem ID",
    "general.remind-me-later": "Ricordamelo al prossimo accesso",
    "login.login-or-signup-with-ts-id": "Accedi o registrati con TeamSystem ID",
    "login.login-or-signup-without-ts-id": "Accedi o registrati senza TeamSystem ID",
    "login.login-to-teamsystem-digital": "Accedi a TeamSystem Digital",
    "login.login-tsid-info":
        " Inserisci il tuo TeamSystem ID (TSID) e la tua Password per effettuare l'accesso a TeamSystem Digital.",
    "login.login-with-credential":
        "Se non disponi di un TeamSystem ID Inserisci le tue credenziali per effettuare l'accesso a TeamSystem Digital.",
    "login.retrieve-your-email": "Recupera la tua Email",
    "login.signup-to-teamsystem-id": "Non hai ancora il TeamSystem ID? Registrati adesso",
    "referrer-gate.whitelist-retrieval-error":
        "Si è verificato un errore nell'accesso alla pagina, si prega di riprovare più tardi.",
    "referrer-gate.unauthorized-referrer": "Impossibile completare l'accesso.",
    "v-forgot-password-message":
        "Inserisci l'Email dell'utenza di cui non ti ricordi la password, ti invieremo una Email per poterla resettare.",
    "v-forgot-password-error-captcha": "Captcha Errato, Ritenta",
    "v-forgot-password-error-invalid-mail": "E-mail non valida, Ritenta",
    "v-forgot-password-error-not-on-system-mail": "E-mail non registrata",
    "v-forgot-password-success":
        "La tua password è stata ripristinata con successo, controlla la tua casella di posta.",
    "v-forgot-password-error":
        "La mail non risulta essere registrata sul nostro sistema, ritenta il processo di recupero password.",
    "v-form-reset-error": "Errore durante il ripristino della password",
    "v-reset-password-message": "Inserisci la nuova password da utilizzare per accedere.",
    "v-retrieve-credentials.company-does-not-exist": "L'azienda specificata non esiste",
    "v-retrieve-credentials.retrieval-error":
        "Si è verificato un errore nel processo di recupero credenziali. Si prega di riprovare più tardi",
    "v-retrieve-credentials.unauthorized":
        "L'azienda selezionata non risulta essere collegata a questa utenza applicativa",
    "v-first-access.modal-title": "Hai effettuato il primo accesso con TeamSystem ID",
    "v-first-access.title": "Possiedi un account TeamSystem digital che vuoi collegare?",
    "v-first-access.subtitle":
        "Collegalo subito per visualizzare i dati del tuo account e continuare ad accedere con il TeamSystem ID.",
    "v-first-access.connect-account-option.confirm": "Si, voglio collegare il mio account TS Digital al TeamSystem ID",
    "v-first-access.connect-account-option.decline": "No, voglio configurare un nuovo account",
    "v-connect-tsid-to-digital-account.modal-title": "Collega il TeamSystem ID al tuo account TS Digital",
    "v-connect-tsid-to-digital-account.connect": "Accedi e collega account",
    "v-connect-tsid-to-digital-account.description":
        "Effettua l'accesso utilizzando le tue credenziali personali per creare il collegamento ed accedere al tuo account con l'utenza unica TeamSystem ID.",
    "v-connect-account-feedback.connecting":
        "Stiamo collegando il tuo TeamSystem ID al tuo account TeamSystem Digital.",
    "v-connect-account-feedback.connecting.long-operation":
        "Questa operazione potrebbe richiedere qualche minuto. Se non vieni reindirizzato automaticamente a {appName} <link>contatta l'assistenza.</link>",
    "v-connect-tsid-to-digital-account.local-user.description":
        "Esiste un account TS Digital associato alla stessa email del TeamSystem ID. Inserisci la password che usi di solito per accedere per creare il collegamento.",
    "v-connect-account-feedback.disclamer":
        "Ti stiamo rendirizzando a {appName}. Questa operazione potrebbe richiedere qualche istante. Se non vieni reindirizzato automaticamente <link>clicca qui</link>.",
    "v-connect-account-feedback.mail-change.disclaimer":
        "<b>Info:</b> riceverai tutte le comunicazioni di TS Digital e degli applicativi in uso alla casella di posta dell'email del TeamSystem ID che hai collegato.",
    "v-connect-account-feedback.success.title": "Collegamento avvenuto con successo",
    "v-connect-account-feedback.success.description":
        "Da oggi potrai accedere al tuo account TS Digital con il TeamSystem ID.",
    "v-connect-account-feedback.loading.title": "Collegamento in corso…",
    "v-connect-account-feedback.loading.description":
        "Stiamo collegando il tuo TeamSystem ID al tuo account TS Digital.",
    "v-connect-account-feedback.error.title": "Qualcosa è andato storto! Il collegamento non è andato a buon fine",
    "v-connect-account-feedback.error.description":
        "Per avviare nuovamente il processo di collegamento clicca su Riprova, se non riesci a risolvere il problema contatta l'assistenza.",
    "v-connect-account-feedback.error.back-to-login": "Torna al login",
    "v-connect-account-feedback.error.user-already-connected.title": "Il collegamento non è andato a buon fine",
    "v-connect-account-feedback.error.user-already-connected.description":
        "Le credenziali personali che hai inserito sono già collegate ad un altro TeamSystem ID. Se non ricordi quale TeamSystem ID hai già collegato, torna al login, accedi con le credenziali personali e visita la sezione “Teamsystem ID” del tuo profilo.",
    "v-connect-account-feedback.error.wrong-credentials.title": "Il collegamento non è andato a buon fine",
    "v-connect-account-feedback.error.wrong-credentials.description":
        "L'email o la password inserite per creare il collegamento tra il TeamSystem ID e il tuo account TS Digital sono errate, riprova ad inserirle.",
    "v-connect-account-feedback.error.user-already-connected-to-tsid.description":
        "Il TeamSystem ID con cui hai effettuato l'accesso è già collegato ad un altro account TeamSystem Digital. Puoi:",
    "v-connect-account-feedback.error.user-already-connected-to-tsid.description.first":
        "Collegare un altro TeamSystem ID a questo account.",
    "v-connect-account-feedback.error.user-already-connected-to-tsid.description.second":
        "Accedere all'altro account TeamSystem Digital e contattare l'assistenza chiedendo di scollegare il TeamSystem ID di riferimento, una volta scollegato provare ad effettuare nuovamente il collegamento.",
    "v-connect-account-feedback.error.user-already-connected-to-tsid.connect-another-user":
        "Collega un altro TeamSystem ID",
    "v-connect-digital-account-to-tsid.modal-title": "Collega un TeamSystem ID al tuo account TS Digital",
    "v-connect-digital-account-to-tsid.description.first-sentence":
        "Effettua l'accesso a TeamSystem per effettuare il collegamento.",
    "v-connect-digital-account-to-tsid.description.second-sentence":
        "Verrai reindirizzato alla pagina di login con il TeamSystem ID dove potrai effettuare l'accesso. Una volta collegato il tuo TeamSystem ID con il tuo account TS Digital potrai effettuare l'accesso a tutti i servizi TeamSystem con un unico account.",
    "v-connect-digital-account-to-tsid.login-and-connect-button": "Accedi e collega TeamSystem ID",
    "v-have-already-tsid-account.description.attention":
        "Dal <b>30/11/2022</b> sarà possibile effettuare l'accesso all'applicazione {appName} <b>solo con il TeamSystem ID</b>.",
    "v-have-already-tsid-account.description.have-already-tsid": "Hai già un TeamSystem ID?",
    "v-have-already-tsid-account.description.login-with-tsid":
        "<b>Accedi</b> con il TeamSystem ID <b>per collegarlo</b> al tuo account.",
    "v-have-already-tsid-account.title.dont-have-tsid": "Non hai un TeamSystem ID?",
    "v-have-already-tsid-account.title.dont-have-tsid.first": "<b>Registrati</b> al TeamSystem ID.",
    "v-have-already-tsid-account.title.dont-have-tsid.second":
        "<b>Effettua il primo accesso</b> al tuo nuovo TeamSystem ID per collegarlo al tuo account TeamSystem Digital.",
    "v-have-already-tsid-account.title.dont-have-tsid.login-or-signup": "Accedi o registrati al TeamSystem ID",
    "v-have-already-tsid-account.title.dont-have-tsid.remember-me-later": "Ricordamelo al prossimo accesso",
    "v-important-notice.description.from":
        "Dal <b>30/11/2022</b> sarà possibile effettuare l'accesso all'applicazione {appName} solamente con il TeamSystem ID.",
    "v-important-notice.description.personal-credential-not-valid":
        "Hai già un TeamSystem ID collegato al tuo account con questa email <b>{tsId}</b> quindi non dovrai preoccuparti di nulla, ricorda però che <b>non saranno più valide le credenziali personali tramite cui hai appena effettuato l'accesso.</b>",
    "v-important-notice.button.ok": "Ok, ho capito",
    "v-invite-feedback.access-denied": "Accesso non consentito",
    "v-invite-feedback.different-emails":
        "Il Teamsystem ID con cui stai cercando di fare l'accesso (<b>{loggedEmail}</b>) non è valido. Ricorda di usare un Teamsystem ID con la stessa email dell'invito, ovvero <b>{inviteEmail}</b>. Se non possiedi un Teamsystem ID con l'email indicata, per accedere a TS Digital dovrai registrarne un altro appositamente.",
    "complete-invitation.failed": "Oops, c'è stato un errore",
    "complete-invitation.succeeded": "Ottimo, hai completato il processo di invito",
    "complete-invitation.youAreAboutToBeRedirected": "Fra poco ti ritroverai sul portale!",
    "complete-invitation.ifRedirectDoesNotWorkClickHere":
        "Se sei ancora qui fra qualche secondo, premi qui per andare sul portale!",
    "v-login.unauthorized-message":
        "<p>Attenzione: l'email o la password che hai inserito sono errati.</p><p>Verifica di non aver inserito erroneamente email e password associati al tuo TeamSystem ID. Per accedere con il TeamSystem ID  clicca sull'apposito pulsante in alto nella pagina di login.</p>",
    "v-login.generic-error": "Ops, c'è stato un errore.",
    "e-password-length": "La password deve contenere almeno 8 caratteri",
    "e-password-uppercase": "La password deve contenere almeno una lettera maiuscola",
    "e-password-lowercase": "La password deve contenere almeno una lettera minuscola",
    "e-password-number": "La password deve contenere almeno un numero",
    "e-password-special-char": "La password deve contenere almeno un carattere speciale",
    "v-already-connected-to-tsid.from-date":
        "Dal <b>1 dicembre 2022</b> puoi accedere a TeamSystem Digital solo con il TeamSystem ID.",
    "v-already-connected-to-tsid.ts-id-email":
        "<b>Hai già un TeamSystem ID collegato ad un tuo account</b> quindi non devi preoccuparti di nulla. L'email associata al tuo TeamSystem ID è: <b>{email}</b>.",
    "v-already-connected-to-tsid.forgot-password":
        "Se non ricordi la password recuperala dalla pagina di login al TeamSystem ID o accedi semplicemente.",
    "v-already-connected-to-tsid.info-box":
        "Il processo di adozione del TeamSystem ID come metodo di autenticazione è iniziato. Potresti notare che alcuni tuoi colleghi accedono ancora senza TeamSystem ID: non è un errore, chiederemo a tutti gli utenti di fare il passaggio nei prossimi giorni.",
    "v-not-connected-to-tsid.from-date":
        "Dal <b>1 dicembre 2022</b> puoi accedere a TeamSystem Digital solo con il TeamSystem ID.",
    "v-not-connected-to-tsid.already-have-a-tsid": "Hai già un TeamSystem ID?",
    "v-not-connected-to-tsid.login-and-connect":
        "<b>Accedi</b> con il TeamSystem ID <b>per collegarlo</b> al tuo account.",
    "v-not-connected-to-tsid.dont-have-a-tsid": "Non hai un TeamSystem ID?",
    "v-not-connected-to-tsid.title.dont-have-a-tsid.first": "<b>Registrati</b> al TeamSystem ID.",
    "v-not-connected-to-tsid.title.dont-have-a-tsid.second":
        "<b>Effettua il primo accesso</b> al tuo nuovo TeamSystem ID per collegarlo al tuo account TeamSystem Digital.",
    "v-not-connected-to-tsid.info-box.forceTsIdLogin":
        "Il processo di adozione del TeamSystem ID come metodo di autenticazione è iniziato. Potresti notare che alcuni tuoi colleghi accedono ancora senza TeamSystem ID: non è un errore, chiederemo a tutti gli utenti di fare il passaggio nei prossimi giorni.",
    "v-not-connected-to-tsid.info-box.notForceTsIdLogin":
        "Il processo di adozione del TeamSystem ID è iniziato. Ricorda che presto non saranno più valide le credenziali che usi solitamente per accedere, ti invitiamo pertanto a collegare al più presto il TeamSystem ID al tuo account, basteranno pochi minuti!"
};

export default it;
