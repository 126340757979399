import styled from "styled-components";

export const Image = styled.div<{ image: any }>`
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${props => props.image});
    flex-shrink: 0;

    @media (max-width: 584px) {
        height: 2rem;
        width: 2rem;
    }
`;

export const Initials = styled.div`
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 50%;
    background: ${props => props.theme.primary.surfaceDefault};
    color: ${props => props.theme.primary.textTitleColor};
    border: 1px solid ${props => props.theme.primary.interactiveDefault};
    font-size: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 584px) {
        height: 2rem;
        width: 2rem;
        font-size: 0.75rem;
    }
`;
