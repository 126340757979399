import styled from "styled-components";

export const ErrorIcon = styled.div`
    color: ${props => props.theme.primary.baseCriticalColor};
    font-size: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    margin-top: 7.5rem;
`;
