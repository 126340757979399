import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { Button, FormControlLabel, Radio, RadioGroup } from "@vapor/react-material";
import LoadingButton from "../../../components/general/loading-button";
import LoggedUser from "../../../components/general/logged-user";
import LoginModal from "../../../components/general/login-modal";
import LoginModalContainer from "../../../components/login/login-modal-container";
import { ButtonsContainer, Subtitle, Title } from "./styled";
import { Spinner } from "../../../components/general/spinner";

import { ssoCreateUser } from "../../../actions/tsid-v3";

import { sendLoginCompleteEvent } from "../../../utils/send-login-complete-event";
import tsIdV3Logout from "../../../utils/tsid-v3-logout";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";

import { SKIP_LINKING_FLAG } from "../../../config";

const FirstAccess = () => {
    const loginOptions = useAppSelector(state => state.loginOptions);
    const tsIdV3 = useAppSelector(state => state.tsIdV3);
    const skipLinking = tsIdV3.callbackState.skipLinking;
    const token = tsIdV3.login.loginResult.accessToken || "notset";
    const refreshToken = tsIdV3.login.loginResult.refreshToken || "notset";
    const idToken = tsIdV3.login.loginResult.idToken || "notset";
    const mode = tsIdV3.callbackState.mode;
    const referrer = tsIdV3.callbackState.originalReferrer || "notset";
    const tsUserCode = tsIdV3.login.tokenData.sub;
    const email = tsIdV3.login.tokenData.email || "notset";
    const firstName = tsIdV3.login.tokenData.given_name || "notset";
    const lastName = tsIdV3.login.tokenData.family_name || "notset";
    const callbackState = tsIdV3.callbackState;
    const ssoCreateUserStatus = tsIdV3.ssoCreateUser.state;

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (SKIP_LINKING_FLAG || skipLinking) {
            dispatch(ssoCreateUser({ token, userData: { tsUserCode, email, firstName, lastName } }));
        }
    }, [skipLinking, token, dispatch, tsUserCode, email, firstName, lastName]);

    const radioOptions = [
        {
            label: <FormattedMessage id="v-first-access.connect-account-option.confirm" />,
            value: "yes",
            checked: false,
            disabled: false
        },
        {
            label: <FormattedMessage id="v-first-access.connect-account-option.decline" />,
            value: "no",
            checked: false,
            disabled: false
        }
    ];

    const [valueRadio, setValueRadio] = useState<string | null>(null);

    /*
     * when the radio button value is "no", we create a new ts digital user with his TSID user
     * identifier associated (tsUserCode)
     */
    const goAhead = () => {
        if (valueRadio === "yes") {
            navigate("/connectTSIdToDigitalAccount");
        } else {
            dispatch(ssoCreateUser({ token, userData: { tsUserCode, email, firstName, lastName } }));
        }
    };

    const doLogout = () => {
        tsIdV3Logout(idToken, loginOptions);
    };

    useEffect(() => {
        if (ssoCreateUserStatus && ssoCreateUserStatus.ended) {
            setTimeout(() => {
                sendLoginCompleteEvent(
                    token,
                    refreshToken,
                    idToken,
                    referrer,
                    mode,
                    callbackState.appId,
                    callbackState.deepLink
                );
            }, 4500);
        }
    }, [
        mode,
        referrer,
        refreshToken,
        ssoCreateUserStatus,
        token,
        idToken,
        callbackState.appId,
        callbackState.deepLink
    ]);

    const loading = ssoCreateUserStatus && (ssoCreateUserStatus.started || ssoCreateUserStatus.ended);
    const showSpinner = (skipLinking || SKIP_LINKING_FLAG) && ssoCreateUserStatus && ssoCreateUserStatus.started;

    return (
        <LoginModalContainer>
            {showSpinner ? (
                <Spinner loading={true} />
            ) : (
                !(SKIP_LINKING_FLAG || skipLinking) && (
                    <LoginModal title={<FormattedMessage id="v-first-access.modal-title" />}>
                        <LoggedUser name={firstName} surname={lastName} email={email} />
                        <Title>
                            <FormattedMessage id="v-first-access.title" />
                        </Title>
                        <Subtitle>
                            <FormattedMessage id="v-first-access.subtitle" />
                        </Subtitle>
                        <RadioGroup
                            name="connect-account"
                            // options={radioOptions}
                            onChange={e => setValueRadio(e.target.value)}
                            value={valueRadio}
                        >
                            {radioOptions.map((radio, index) => (
                                <FormControlLabel
                                    key={`connect-account-option-${index}`}
                                    control={<Radio />}
                                    label={radio.label}
                                    value={radio.value}
                                    disabled={radio.disabled}
                                />
                            ))}
                        </RadioGroup>
                        <ButtonsContainer>
                            <Button variant="outlined" fullWidth={true} disabled={loading} onClick={doLogout}>
                                <FormattedMessage id="general.annulla" />
                            </Button>
                            <LoadingButton loading={loading} disabled={!valueRadio} onClick={goAhead} fullWidth={true}>
                                <FormattedMessage id="general.continue" />
                            </LoadingButton>
                        </ButtonsContainer>
                    </LoginModal>
                )
            )}
        </LoginModalContainer>
    );
};

export default FirstAccess;
