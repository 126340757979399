import { createReducer } from "@reduxjs/toolkit";
import { forgotPassword, forgotPasswordReset, resetPassword } from "../actions/auth";

const defaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export const forgot = createReducer(defaultState, builder => {
    builder
        .addCase(forgotPassword.pending, state => {
            state.status = { ...defaultState.status, started: true };
            return state;
        })
        .addCase(forgotPassword.fulfilled, state => {
            state.status = { ...defaultState.status, ended: true };
            return state;
        })
        .addCase(forgotPassword.rejected, (state, { error }) => {
            state.status = {
                ...defaultState.status,
                error: true,
                errorInfo: { code: error.code || "notset", message: error.message || "notset" }
            };
            return state;
        })
        .addCase(forgotPasswordReset, _ => defaultState)
        .addDefaultCase(state => state);
});

const resetPasswordDefaultState = {
    status: {
        started: false,
        error: false,
        ended: false,
        errorInfo: {
            code: "",
            message: ""
        }
    }
};

export const reset = createReducer(resetPasswordDefaultState, builder => {
    builder
        .addCase(resetPassword.pending, state => {
            state.status = { ...defaultState.status, started: true };
            return state;
        })
        .addCase(resetPassword.fulfilled, state => {
            state.status = { ...defaultState.status, ended: true };
            return state;
        })
        .addCase(resetPassword.rejected, (state, { error }) => {
            state.status = {
                ...defaultState.status,
                error: true,
                errorInfo: { code: error.code || "notset", message: error.message || "notset" }
            };
            return state;
        })
        .addDefaultCase(state => state);
});
