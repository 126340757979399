import React, { useEffect, useCallback } from "react";
import { FormattedMessage } from "react-intl";

import { Button } from "@vapor/react-material";

import LoginModal from "../../components/general/login-modal";
import LoginModalContainer from "../../components/login/login-modal-container";
import { sendLoginCompleteEvent } from "../../utils/send-login-complete-event";
import { Description } from "./styled";
import { useAppSelector } from "../../hooks/redux";

const InviteCompleteFeedbackView = () => {
    const invitationCompleteHasFailed = useAppSelector(state => state.tsIdV3.completeInvitation.status.error);
    const { accessToken, idToken, refreshToken } = useAppSelector(state => state.tsIdV3.login.loginResult);
    const { appId, deepLink, referrer, mode } = useAppSelector(state => state.tsIdV3.callbackState);

    const failedOrSucceededMessage = invitationCompleteHasFailed
        ? "complete-invitation.failed"
        : "complete-invitation.succeeded";

    const goToPortal = useCallback(() => {
        if (accessToken && idToken && refreshToken && referrer && mode) {
            sendLoginCompleteEvent(accessToken, refreshToken, idToken, referrer, mode, appId, deepLink);
        }
    }, [accessToken, appId, deepLink, idToken, refreshToken, referrer, mode]);

    useEffect(() => {
        const timerId = setTimeout(() => {
            goToPortal();
        }, 5000);
        return () => {
            clearTimeout(timerId);
        };
    }, [goToPortal]);

    return (
        <LoginModalContainer>
            <LoginModal title={<FormattedMessage id={failedOrSucceededMessage} />}>
                <Description>
                    <br />
                    <br />
                    <FormattedMessage id="complete-invitation.youAreAboutToBeRedirected" />{" "}
                </Description>
                <Button onClick={goToPortal} size="medium" variant="contained">
                    <FormattedMessage id="complete-invitation.ifRedirectDoesNotWorkClickHere" />
                </Button>
            </LoginModal>
        </LoginModalContainer>
    );
};

export default InviteCompleteFeedbackView;
