import { TS_ID_END_SESSION_ENDPOINT, TS_ID_POST_LOGOUT_REDIRECT_URL, DIGITAL_URL } from "../config";

function tsIdV3Logout(idToken: string, loginOptions: object, redirectUrl?: string) {
    const stateQueryString = Object.entries(loginOptions)
        .filter(([_, val]) => val !== null)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");

    const tsIdParams = {
        id_token_hint: idToken,
        post_logout_redirect_uri: TS_ID_POST_LOGOUT_REDIRECT_URL,
        state: redirectUrl || `${DIGITAL_URL}?${stateQueryString}`
    };

    const queryString = Object.entries(tsIdParams)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");

    window.location.href = `${TS_ID_END_SESSION_ENDPOINT}?${queryString}`;
}

export default tsIdV3Logout;
