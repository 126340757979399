import React from "react";

import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";

import { Container, Email, FeedbackContainer, Name, SuccessIcon, UserInfoContainer } from "./styled";
import Avatar from "../avatar";
import { FormattedMessage } from "react-intl";

type LoggedUserProps = {
    name: string;
    surname: string;
    email: string;
    image?: any;
    showFeedback?: boolean;
};
const LoggedUser = ({ name, surname, email, image, showFeedback = false }: LoggedUserProps) => {
    return (
        <Container>
            <Avatar image={image} name={name} surname={surname} />
            <UserInfoContainer>
                <Name>
                    {name} {surname}
                </Name>
                <Email>{email}</Email>
            </UserInfoContainer>
            {showFeedback && (
                <FeedbackContainer>
                    <SuccessIcon icon={faCheckCircle} />
                    <FormattedMessage id="c-logged-user.success-feedback" />
                </FeedbackContainer>
            )}
        </Container>
    );
};

export default LoggedUser;
