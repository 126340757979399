import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import sha256 from "sha256";

import { getNonce } from "../auth";
import { postRestCall } from "../../utils/rest-api-call";
import { API_URL } from "../../config";

export const connectTsIdPrefix = "CHECK_TS_ID_CONNECTED";
export const CONNECT_TS_ID_RESET = `${connectTsIdPrefix}/reset`;

export type ConnectTsIdParameters = { userId: string; password: string; tsIdToken: string };
export const connectTsId = createAsyncThunk(
    connectTsIdPrefix,
    async ({ userId, password, tsIdToken }: ConnectTsIdParameters) => {
        const nonce = await getNonce(userId);
        await postRestCall(
            `${API_URL}/login/tsid/v3/connect`,
            { securityToken: tsIdToken },
            {},
            { tsDigitalDigest: sha256(sha256(userId + password) + nonce), tsDigitalId: userId }
        );

        return { userId };
    }
);

export type ConnectTsIdWithDigestParameters = { userId: string; digest: string; tsIdToken: string };
export const connectTsIdWithDigest = createAsyncThunk(
    connectTsIdPrefix,
    async ({ userId, digest, tsIdToken }: ConnectTsIdWithDigestParameters) => {
        await postRestCall(
            `${API_URL}/login/tsid/v3/connect`,
            { securityToken: tsIdToken },
            {},
            { tsDigitalDigest: digest, tsDigitalId: userId }
        );

        return { userId };
    }
);
export const connectTsIdReset = createAction(CONNECT_TS_ID_RESET);
