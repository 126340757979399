import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";

import { SpinnerContainer } from "./styled";
import type { SpinnerSize } from "./styled";

type SpinnerPropTypes = {
    children?: any;
    /** if true, displays the spinner, otherwise displays the children */
    loading: boolean;
    /** size of the spinner image */
    spinnerSize: SpinnerSize;
};

/**
 * Component that either renders a centered spinner
 * or renders its children based on the value of the
 * loading prop.
 */
export const Spinner = ({ children, loading, spinnerSize = "default" }: SpinnerPropTypes) =>
    loading ? (
        <SpinnerContainer size={spinnerSize}>
            <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
        </SpinnerContainer>
    ) : (
        children
    );
Spinner.defaultProps = {
    spinnerSize: "large"
};
