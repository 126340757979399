import styled from "styled-components";

export const Description = styled.div`
    color: rgb(90, 104, 114);
    font-size: 1rem;
    font-family: Roboto;
    font-weight: normal;
    letter-spacing: 0.44px;
    line-height: 1.5rem;
    margin-bottom: 4.5rem;
`;
