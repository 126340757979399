import React, { ReactNode } from "react";

import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/redux";

type TsIdGateProps = {
    children: ReactNode;
};
/**
 * Only renders its children if a TSID token is present in the redux state,
 * otherwise redirects to the main login page.
 */
const TsIdGate = ({ children }: TsIdGateProps) => {
    const tsIdTokenPresent = useAppSelector(state => state.tsIdV3.login.loginResult.accessToken !== undefined);
    return tsIdTokenPresent ? children : <Navigate to="/" />;
};

export default TsIdGate;
