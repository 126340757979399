import { Buffer } from "buffer";

class Base64Utils {
    static encode(objectToEncode: object) {
        const convertedObject = JSON.stringify(objectToEncode);
        return Buffer.from(convertedObject).toString("base64");
    }

    static decode(objectToDecode: string) {
        const decodedObject = Buffer.from(objectToDecode, "base64").toString("ascii");
        return JSON.parse(decodedObject);
    }
}

export default Base64Utils;
